import { ProductCore, ProductShopDataType, ShopPages } from './types'
import { Currency } from 'prices/types'
import { PriceManager, SUBSCRIPTION_REGION_MAP, SubscriptionKeys } from 'prices/priceManager'
import * as productCatalog from './allProducts'
import * as productFeatures from './productFeatures'
import { ACCESSORIES_FILTER } from 'constants/accessories'
import cloneDeep from 'lodash.clonedeep'
import { AccessoriesList } from 'constants/types'
import { regionIsEU, RegionShort } from 'utils/internationalization'
import { coverImperial, coverMetric, coverPerfectImperial, coverPerfectMetric, getFeaturesWithDimensions } from 'products/productFeatures'
import { getUnitForRegion } from 'constants/units'
import { allProductsList } from 'products/utils'
import rootStore from 'stores'
import {
	airLiteComforterImages,
	carbonAirPillowImages,
	foundationImages,
	gravityBlanketImages,
	hypericeVenomBack,
	hypervoltProImages,
	lavenderSprayImages,
	normatecLegPulse,
	pod3CoverImages,
	podProCoverOriginalImages,
	podProCoverPerfectImages,
	podSheetDarkGraySetImages,
	podSheetSetImages,
	protectorImages,
	sleepEssentialBundleImages,
	sleepFitnessKitImages,
} from './productImages'

export class ProductManager {
	public static cloneProductWithAttributes(product: ProductCore, customAttributes: { [key: string]: string | boolean }) {
		const newProduct = cloneDeep(product) as ProductCore
		if (customAttributes && typeof customAttributes === 'object') {
			newProduct.custom = customAttributes
		}
		return newProduct
	}
	constructor(private readonly priceManager: PriceManager) {}

	public getCompleteVariant(product: ProductCore, variantName: string) {
		if (!(variantName in product.variants)) {
			throw new Error(`Variant name ${variantName} not found in product ${product.name}`)
		}
		// const fullProduct = this.withPrices(product)
		const fullProduct = product
		const variant = fullProduct.variants[variantName]

		return {
			productDescription: fullProduct.description,
			variantName: variant.name,
			productTitle: fullProduct.name,
			variantId: variant.id,
			productId: fullProduct.id,
			handle: fullProduct.handle,
			sellingPlanId: fullProduct.sellingPlanId ?? null,
		}
	}

	public getCoverShopProducts(region: RegionShort) {
		const isUk = region === 'uk'
		const isEu = regionIsEU(region)
		const isCA = region === 'ca'
		const isAU = region === 'au'
		const isSE = region === 'se'
		const isDK = region === 'dk'

		const data: ProductShopDataType = {
			products: [productCatalog.PodCoverProducts.Pod2ProCoverStandard, productCatalog.PodCoverProducts.Pod3CoverStandard, productCatalog.PodCoverProducts.Pod3CoverPerfect],
			features: [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverImperial, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverImperial, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectImperial, productFeatures.cover3PerfectFeatures) },
			],
			images: [podProCoverOriginalImages, pod3CoverImages, podProCoverPerfectImages],
			defaultProductSelected: 2,
			modelSelectComponentType: 'cards',
			modelSelectProps: {
				labels: [
					{
						title: 'Pod 2 Cover',
						description: `Fits ${getUnitForRegion('10"-11"', region)} mattresses`,
						badgeText: null,
						id: 'pod_pro_cover',
					},
					{
						title: 'Pod 3 Cover',
						description: `Fits ${getUnitForRegion('10"-11"', region)} mattresses. Now with increased comfort and improved tracking`,
						badgeText: null,
						id: 'pod_3_cover',
					},
					{
						title: 'Pod 3 Cover with PerfectFit',
						description: `Fits all beds. Now with increased comfort and improved tracking`,
						badgeText: 'Most Popular',
						id: 'pod_3_cover_perf',
					},
				],
				legendLabel: 'Select Cover type',
			},
			variantSelectComponentType: 'dropdown',
			variantSelectLabel: 'Size',
			dropdownItems: [
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'Full' },
						{ value: 'queen', label: 'Queen' },
						{ value: 'king', label: 'King' },
						{ value: 'caliking', label: 'Cali King' },
					],
					defaultVariantSelected: 'queen',
				},
			],
			shipping: {
				full: ['Canada', 'UK'],
				queen: ['Canada', 'UK'],
				king: ['Canada', 'UK'],
				caliking: ['UK'],
				eulargedouble: ['EU'],
				eudouble: ['EU'],
				eusuperking: ['EU'],
			},
			whatsInTheBoxSrc: 'https://eightsleep.imgix.net/pod-cover-winb.png?v=1671044162',
		}

		// data.images = data.images.map(() => podProCoverMembershipImages)
		if (isCA) {
			data.dropdownItems = [
				{
					title: 'Canada Sizes',
					items: [
						{ value: 'cafull', label: 'CA Full' },
						{ value: 'caqueen', label: 'CA Queen' },
						{ value: 'caking', label: 'CA King' },
					],
					defaultVariantSelected: 'caqueen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isUk) {
			data.dropdownItems = [
				{
					title: 'UK Sizes',
					items: [
						{ value: 'ukdouble', label: 'UK Double (135x190\xA0cm)' },
						{ value: 'ukking', label: 'UK King (150x200\xA0cm)' },
						{ value: 'uksuperking', label: 'UK Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'ukdouble',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in UK)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in UK)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in UK)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in UK)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isEu) {
			data.dropdownItems = [
				{
					title: 'Europe Sizes',
					items: [
						{ value: 'eudouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'eulargedouble', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'eusuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'eudouble',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isAU) {
			data.dropdownItems = [
				{
					title: 'Australian Sizes',
					items: [
						{ value: 'audouble', label: 'AU Double' },
						{ value: 'auqueen', label: 'AU Queen' },
						{ value: 'auking', label: 'AU King' },
					],
					defaultVariantSelected: 'auqueen',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isSE) {
			data.dropdownItems = [
				{
					title: 'Swedish Sizes',
					items: [
						{ value: 'sedouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'seking', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'sesuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'seking',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isDK) {
			data.dropdownItems = [
				{
					title: 'Danish Sizes',
					items: [
						{ value: 'dkdouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'dkking', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'dksuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'dkking',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		return data
	}

	public getAccessoriesProducts(region: RegionShort): AccessoriesList[] {
		const isUk = region === 'uk'
		const isEu = regionIsEU(region)
		const isCA = region === 'ca'
		const isAU = region === 'au'

		if (isUk || isEu || isCA || isAU) {
			return []
		}

		const items = [
			{
				name: ACCESSORIES_FILTER.MadeForPod,
				items: [
					{
						...this.getCompleteVariant(productCatalog.BundleProducts.SleepEssentialBundle, 'full'),
						productDescription: ['Completes your perfect Pod setup'],
						productImage: 'https://eightsleep.imgix.net/45_essentials_bundle_93635696-6cb6-4571-b95a-78608478c393.jpg?v=1633522272',
						buttonTo: '/product/the-sleep-essentials-bundle',
						saveText: 'Save ###',
					},
					{
						...this.getCompleteVariant(productCatalog.ProtectorProducts.PodProtector, 'queen'),
						productDescription: ['Water and dust-proof protector'],
						productImage: 'https://eightsleep.imgix.net/45_pod_protector_da7acab2-47cc-44cc-9cb8-6c52d035d144.jpg?v=1633522055',
						buttonTo: '/product/waterproof-protector',
					},
					{
						...this.getCompleteVariant(productCatalog.SheetProducts.SheetSet, 'full'),
						productDescription: ['300-thread count, 100% Eucalyptus'],
						productImage: 'https://eightsleep.imgix.net/45_sheet_set_9162958b-8aa8-4d67-9842-5b0b10bfd9e6.jpg?v=1633522034',
						productImageDark: 'https://eightsleep.imgix.net/sheets-03.png?v=1695051873',
						buttonTo: '/product/the-pod-sheet-set',
					},
					{
						...this.getCompleteVariant(productCatalog.PillowProducts.CarbonAirPillow, 'onepack'),
						productDescription: ['Cool carbon-infused foam pillow'],
						productImage: 'https://eightsleep.imgix.net/45_carbon_air_pillow_1.jpg?v=1633521819',
						buttonTo: '/product/the-carbon-air-pillow',
						badge: 'Best seller',
					},
					{
						...this.getCompleteVariant(productCatalog.ComforterProducts.AirLiteComforter, 'queen'),
						productDescription: ['Breathable and soft comforter'],
						productImage: 'https://eightsleep.imgix.net/45_air_lite_comforter_b190df7d-59af-416e-911c-ae31f01af4d0.jpg?v=1633522228',
						buttonTo: '/product/air-lite-comforter',
					},
					{
						...this.getCompleteVariant(productCatalog.FoundationProducts.SimpleFoundation, 'caliking'),
						productDescription: ['Foundation designed for the Pod'],
						productImage: 'https://eightsleep.imgix.net/45_pod_foundation_62bcc56e-ba89-4ce0-80c4-b42ce30254b3.jpg?v=1633522208',
						buttonTo: '/product/foundation',
					},
				],
			},
			{
				name: ACCESSORIES_FILTER.WindDown,
				items: [
					{
						...this.getCompleteVariant(productCatalog.BlanketProducts.BrandedGravity, 'standard'),
						productDescription: ['Throw size, 16lb weighted blanket'],
						productImage: 'https://eightsleep.imgix.net/45_gravity_blanket_1.jpg?v=1633522158',
						buttonTo: '/product/gravity-blanket',
					},
					{
						...this.getCompleteVariant(productCatalog.SprayProducts.LavenderSpray, 'standard'),
						productDescription: ['Designed to promote relaxation'],
						productImage: 'https://eightsleep.imgix.net/45_sleep_spray_b1766c32-1a48-46de-8fd6-2a1b7cee0ab0.jpg?v=1633522009',
						buttonTo: '/product/lavender-sleep-spray',
					},
					// {
					// 	...this.getCompleteVariant(productCatalog.BundleProducts.MelatoninProBundle, 'standard'),
					// 	productDescription: ['30 day supply of Magnesium CitraMate, Melaton-3, and RecoveryPro.'],
					// 	productImage: 'https://eightsleep.imgix.net/melatonin_pro_bundle_6865573f-9c87-42ca-9e33-6f756aaaccf3.jpg?v=1633521985',
					// 	buttonTo: '/product/melatonin-pro-bundle',
					// },
					// {
					// 	...this.getCompleteVariant(productCatalog.CanalProducts.CanopyHumidifier, 'white'),
					// 	productDescription: ['A completely reimagined humidifier that promotes healthy skin and alleviates symptoms of cold and flu.'],
					// 	productImage: 'https://eightsleep.imgix.net/canopy.webp?v=1658850509',
					// 	buttonTo: '/product/canopy-humidifier',
					// },
				],
			},
			{
				name: ACCESSORIES_FILTER.BoostYourRecovery,
				items: [
					{
						...this.getCompleteVariant(productCatalog.HypericeProducts.VenomBack, 'standard'),
						productDescription: ['Vibration and heat to relax muscles'],
						productImage: 'https://eightsleep.imgix.net/venom1_a5801fa2-2782-4260-a000-4ea8b89b726d.jpg?v=1692281423',
						buttonTo: '/product/venom-back',
					},
					{
						...this.getCompleteVariant(productCatalog.HypericeProducts.HypervoltPlus, 'standard'),
						productDescription: ['Percussion device to loosen muscles'],
						productImage: 'https://eightsleep.imgix.net/hyperice1_b7a72f17-9eb7-40f8-9b0d-e42eee0db9bf.jpg?v=1692281424',
						buttonTo: '/product/hypervolt-2-pro',
					},
					{
						...this.getCompleteVariant(productCatalog.NormaTecProducts.LegPulse2, 'standard'),
						productDescription: ['Compression boots for recovery'],
						productImage: 'https://eightsleep.imgix.net/normatec1_a461ffaa-04a3-4dbf-bb8a-cd313e89075f.jpg?v=1692281424',
						buttonTo: '/product/leg-pulse',
					},
				],
			},
		]

		return items
	}

	public getShopPageProducts(url: ShopPages, region: RegionShort): ProductShopDataType {
		let data: ProductShopDataType
		switch (url) {
			case 'cover':
				data = this.getCoverShopProducts(region)
				return data
			case 'pillow':
				data = {
					products: [productCatalog.PillowProducts.CarbonAirPillow, productCatalog.PillowProducts.CarbonAirPillowKing],
					features: [
						{ title: 'About the Carbon Air Pillow?', items: productFeatures.pillowFeatures },
						{ title: 'About the Carbon Air Pillow?', items: productFeatures.kingPillowFeatures },
					],
					images: [carbonAirPillowImages, carbonAirPillowImages],
					defaultProductSelected: 1,
					modelSelectComponentType: 'radio_buttons',
					modelSelectProps: {
						labels: [
							{
								title: 'Queen',
								id: 'queen',
							},
							{
								title: 'King',
								id: 'king',
							},
						],
						legendLabel: 'Size',
					},
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Quantity',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'onepack', label: 'One Pack' },
								{ value: 'twopack', label: 'Two Pack' },
							],
							defaultVariantSelected: 'twopack',
						},
					],
				}
				return data
			case 'foundation':
				data = {
					products: [productCatalog.FoundationProducts.SimpleFoundation],
					features: null,
					images: [foundationImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'sheets':
				data = {
					products: [productCatalog.SheetProducts.SheetSet, productCatalog.SheetProducts.SheetSetGray],
					features: [
						{ title: 'About the Pod Sheet Set', items: productFeatures.sheetsFeatures },
						{ title: 'About the Pod Sheet Set', items: productFeatures.sheetsGrayFeatures },
					],
					images: [podSheetSetImages, podSheetDarkGraySetImages],
					defaultProductSelected: 0,
					modelSelectComponentType: 'color_picker',
					modelSelectProps: {
						labels: [
							{
								title: 'White',
								id: 'white',
								description: null,
								badgeText: null,
								color: '#f6f6f6',
							},
							{
								title: 'Dark Charcoal',
								id: 'gray',
								description: null,
								badgeText: null,
								color: '#b8b8b8',
							},
						],
						legendLabel: 'Select your size',
					},
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Select a pack',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'comforter':
				data = {
					products: [productCatalog.ComforterProducts.AirLiteComforter],
					features: null,
					images: [airLiteComforterImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								// { value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'protector':
				data = {
					products: [productCatalog.ProtectorProducts.PodProtector],
					features: null,
					images: [protectorImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'essentials-bundle':
				data = {
					products: [productCatalog.BundleProducts.SleepEssentialBundle],
					features: null,
					images: [sleepEssentialBundleImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'king',
						},
					],
				}
				return data
			case 'melatonin-bundle':
				data = {
					products: [productCatalog.BundleProducts.MelatoninProBundle],
					features: null,
					images: [sleepFitnessKitImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			case 'gravity-blanket':
				data = {
					products: [productCatalog.BlanketProducts.BrandedGravity],
					features: null,
					images: [gravityBlanketImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			case 'spray':
				data = {
					products: [productCatalog.SprayProducts.LavenderSpray],
					features: null,
					images: [lavenderSprayImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
					displayPrice: '',
				}
				return data
			case 'hypervolt':
				data = {
					products: [productCatalog.HypericeProducts.HypervoltPlus],
					features: [{ title: 'Standard features', items: productFeatures.hypervoltProFeatures }],
					images: [hypervoltProImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			case 'venom-back':
				data = {
					products: [productCatalog.HypericeProducts.VenomBack],
					features: null,
					images: [hypericeVenomBack],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			case 'leg-pulse':
				data = {
					products: [productCatalog.NormaTecProducts.LegPulse2],
					features: null,
					images: [normatecLegPulse],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			default:
				data = this.getCoverShopProducts(region)
				return data
		}
	}

	public getPageProducts(url: string, region: RegionShort, saleName: string): AccessoriesList[] {
		const memberPage: AccessoriesList[] = [
			{
				name: ACCESSORIES_FILTER.NewPodTechnology,
				items: [
					{
						...this.getCompleteVariant(productCatalog.PodCoverProducts.Pod3CoverPerfect, this.getDefaultVariantForRegion(region)),
						buttonTo: '/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208',
						productDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						mobileDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						ctaText: region === '' || region === 'ca' ? `Up to %%% off` : `Up to ### off`,
						badge: `Members ${saleName}`,
						large: true,
						productImage: 'https://eightsleep.imgix.net/cover-square-p5_shadow_2_1_d6fe7a59-edd1-4187-8202-a4b204eae10a.png?v=1699573174',
					},
				],
			},
			...this.getAccessoriesProducts(region),
		]

		switch (url) {
			case 'member-accessories':
				return memberPage
			case 'accessories':
				return this.getAccessoriesProducts(region)
			default:
				return []
		}
	}

	public getProductFromID(productID: number): ProductCore | null {
		for (const product of allProductsList) {
			if (product.id === productID) {
				return product
			}
		}
		return null
	}

	public getCartDescription(product: ProductCore): string[] {
		function getStandardVariantPrice(product: ProductCore, installments: number, currency: Currency) {
			return PriceManager.formatPriceToCurrencyNoDecimal(
				(PriceManager.formatPrices(rootStore.priceStore.getVariantPrices(product.variants.standard.id), currency).price || 0) / installments / 100,
				currency
			)
		}

		const formattedDescription: string[] = []
		for (const line of product.cartDescription || []) {
			const append = ''
			const currency = rootStore.priceStore.currency

			if (SUBSCRIPTION_REGION_MAP[product.handle] && SUBSCRIPTION_REGION_MAP[product.handle][PriceManager.getCurrencyRegion(currency)]) {
				const membershipKey = product.handle as SubscriptionKeys
				const price = PriceManager.getCurrencySubscriptionPrice(membershipKey, currency)
				const text = (append + line).replaceAll('XX', price)
				formattedDescription.push(text)
			} else {
				formattedDescription.push(append + line)
			}
		}
		return formattedDescription
	}

	public getDefaultVariantForRegion(region: RegionShort) {
		if (region === 'au') {
			return 'auqueen'
		} else if (regionIsEU(region)) {
			return 'eudouble'
		} else if (region === 'ca') {
			return 'caqueen'
		} else if (region === 'uk') {
			return 'ukdouble'
		}
		return 'queen'
	}
}
