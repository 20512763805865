import { toJS } from 'mobx'
import { Currency, CurrencyDiscount, CurrencyMap, DiscountedVariants, FormattedPrices, Prices, ProductPriceMap } from 'prices/types'
import { discountCodes } from 'promos'
import { CartLineItem } from 'cart/types'
import { Product, ProductCore, ProductVariant, VariantCore, VariantKey } from 'products/types'
import cloneDeep from 'lodash.clonedeep'
import {
	Collection10PercentOff,
	Collection10PercentOffVariants,
	Collection20PercentOff,
	Collection20PercentOffVariants,
	Collection25PercentOff,
	Collection25PercentOffVariants,
	Collection30PercentOff,
	Collection30PercentOffVariants,
	isPodBedVariantId,
} from 'products/utils'
import { RegionShort } from 'utils/internationalization'
import { MattressProducts, PodCoverProducts } from 'products/allProducts'
import { VARIABLE_CURRENCY_MAP } from 'stripe_lib/scripts'

export const SUBSCRIPTION_REGION_MAP = {
	'8-standard-membership': {
		'': 10,
		eu: 11,
		uk: 9,
		ca: 13,
		au: 16,
		se: 133,
		dk: 93,
	},
	'8-pro-membership': {
		'': 15,
		eu: 17,
		uk: 14,
		ca: 20,
		au: 24,
		se: 200,
		dk: 140,
	},
	'8-plus-membership': {
		'': 24,
		eu: 27,
		uk: 22,
		ca: 32,
		au: 38,
		se: 320,
		dk: 224,
	},
}

export type SubscriptionKeys = keyof typeof SUBSCRIPTION_REGION_MAP

function discountCartLineByPercent(item: CartLineItem, percent: number) {
	const newItem = cloneDeep(toJS(item)) as CartLineItem
	const percentOff = percent / 100
	newItem.price = item.price * (1 - percentOff)
	newItem.comparePrice = item.comparePrice ? item.comparePrice : item.price
	return newItem
}

function discountSingleCurrency(countryPrices: Prices, percent: number) {
	if (!countryPrices.price) {
		return countryPrices
	}
	const newPrices = cloneDeep(countryPrices) as Prices
	const discountPrice = (parseFloat(newPrices.price!) * (100 - percent)) / 100
	newPrices.price = discountPrice.toString()
	newPrices.comparePrice = newPrices.comparePrice ?? countryPrices.price
	return newPrices
}

function discountCurrencyMapByPercent(prices: CurrencyMap, percent: number) {
	const pricesCopy = cloneDeep(prices) as CurrencyMap
	let currencyCode: keyof typeof pricesCopy
	for (currencyCode in pricesCopy) {
		const countryPrices = pricesCopy[currencyCode]
		pricesCopy[currencyCode] = discountSingleCurrency(countryPrices, percent)
	}
	return pricesCopy
}

export class PriceManager {
	private _appliedDiscount: DiscountedVariants | undefined = undefined
	private shouldDiscountAccessories = false
	private promoDiscountCode = ''

	constructor(public productPrices: ProductPriceMap) {}

	public static podMaxMonthsAtZeroInterest = 36
	public static coverMaxMonthsAtZeroInterest = 24
	public static emptyPrice: CurrencyMap = {
		USD: { price: null, comparePrice: null },
		GBP: { price: null, comparePrice: null },
		EUR: { price: null, comparePrice: null },
		CAD: { price: null, comparePrice: null },
		AUD: { price: null, comparePrice: null },
		SEK: { price: null, comparePrice: null },
		DKK: { price: null, comparePrice: null },
	}

	public static getFinancingAmount(cents: number, region: RegionShort) {
		if (region === '' || region === 'us') {
			return this.getAffirmFinancingAmount(cents)
		}
		if (region === 'eu') {
			return this.getKlarnaFinancingAmount(cents)
		}

		return NaN
	}

	public static getAffirmMaxMonths(cents: number) {
		let months = 6

		if (cents >= 100 * 100) {
			months = 12
		}
		if (cents > 1495 * 100) {
			months = 24
		}
		if (cents > 2000 * 100) {
			months = 36
		}

		return months
	}

	public static getKlarnaMaxMonths(cents: number) {
		return 3
	}

	public static getAffirmFinancingAmount(price: number) {
		if (!price) {
			return NaN
		}
		if (price >= 50) {
			return Math.ceil(price / this.getAffirmMaxMonths(price * 100))
		}
		return NaN
	}

	public static getKlarnaFinancingAmount(price: number) {
		if (!price) {
			return NaN
		}
		return Math.ceil(price / this.getKlarnaMaxMonths(price * 100))
	}

	public static regionHasAffirm(region: RegionShort): boolean {
		return region === '' || region === 'us' || region === 'ca'
	}

	public static regionHasKlarna(region: RegionShort): boolean {
		return region === 'eu' || region === 'uk'
	}

	public static currencyHasAffirm(currency: Currency): boolean {
		return currency === 'USD' || currency === 'CAD'
	}

	public static currencyHasKlarna(currency: Currency): boolean {
		return currency === 'EUR' || currency === 'GBP'
	}

	public static getRegionCurrency(region: RegionShort): Currency {
		switch (region) {
			case 'ca':
				return 'CAD'
			case 'eu':
				return 'EUR'
			case 'se':
				return 'SEK'
			case 'dk':
				return 'DKK'
			case 'uk':
				return 'GBP'
			case 'au':
				return 'AUD'
			default:
				return 'USD'
		}
	}

	public static getCurrencyRegion(currency: Currency): RegionShort {
		switch (currency) {
			case 'AUD':
				return 'au'
			case 'CAD':
				return 'ca'
			case 'EUR':
				return 'eu'
			case 'GBP':
				return 'uk'
			case 'SEK':
				return 'se'
			case 'DKK':
				return 'dk'
			default:
				return ''
		}
	}

	public static formatPriceToCurrency(price: number | string, currency: Currency) {
		if (isNaN(price as number)) {
			return ''
		}

		let currencyFormat = currency
		if (currency === 'CAD' || currency === 'AUD' || !currency) {
			currencyFormat = 'USD'
		}

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currencyFormat,
			currencyDisplay: 'narrowSymbol',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})

		const formatted = formatter.format(price as number)

		return formatted.replace(/kr\s/, 'kr')
	}

	public static formatPriceToCurrencyNoDecimal(price: number | string, currency: Currency) {
		if (isNaN(price as number)) {
			return ''
		}

		let currencyFormat = currency
		if (currency === 'CAD' || currency === 'AUD' || !currency) {
			currencyFormat = 'USD'
		}

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currencyFormat,
			currencyDisplay: 'narrowSymbol',
			minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		})

		const formatted = formatter.format(price as number)

		return formatted.replace(/kr\s/, 'kr')
	}

	public static formatPriceToRegionNoDecimal(price: number, region: RegionShort) {
		return this.formatPriceToCurrencyNoDecimal(price, this.getRegionCurrency(region))
	}

	private static clonePrices(currency: CurrencyMap) {
		const result: Partial<CurrencyMap> = {}
		Object.keys(currency).forEach((code) => {
			const prices = currency[code as Currency]
			result[code as Currency] = { ...prices }
		})
		return result as CurrencyMap
	}

	private subtractDiscountInCurrencyMap(originalPrices: CurrencyMap, discounts: CurrencyDiscount) {
		const newPrices = PriceManager.clonePrices(originalPrices)
		if (!Object.keys(newPrices).length) {
			return PriceManager.emptyPrice
		}

		Object.entries(newPrices).forEach((entry) => {
			const currency = entry[0] as Currency
			const prices = entry[1]
			let newPrice = prices.price
			let newComparePrice = prices.comparePrice
			const _discount = discounts[currency]
			if (prices.price && _discount) {
				const _price = parseFloat(prices.price)

				let discountPrice: number = _price

				if (typeof _discount === 'number') {
					discountPrice = _price - _discount
				} else {
					const percentOff = parseInt(_discount.substr(0, 2)) / 100
					discountPrice = discountPrice * (1 - percentOff)
				}
				newPrice = discountPrice.toString()
				if (!newComparePrice) {
					newComparePrice = prices.price
				}
			}
			newPrices[currency].price = newPrice
			newPrices[currency].comparePrice = newComparePrice
		})

		return newPrices
	}

	public static getPreferredCurrency(prices: CurrencyMap, currency: Currency) {
		const localCurrrencyAvailable = !!prices[currency] && 'price' in prices[currency] && prices[currency].price !== null
		if (localCurrrencyAvailable) return currency
		return 'USD'
	}

	public setPrices(prices: ProductPriceMap) {
		this.productPrices = prices
	}

	public getVariantPrices(prices: ProductPriceMap, variantId: number | string) {
		if (!prices || !Object.keys(prices).length) {
			return PriceManager.emptyPrice
		}
		const variant = prices[variantId]
		if (!variant || !Object.keys(variant).length) {
			return PriceManager.emptyPrice
		}
		if (!variant) {
			return PriceManager.emptyPrice
		}
		return variant.prices
	}

	public static formatPrices(prices: CurrencyMap, currency: Currency) {
		const localPrices = prices[currency]
		const priceCents = localPrices.price ? parseFloat(localPrices.price) * 100 : 0
		const compareCents = localPrices.comparePrice ? parseFloat(localPrices.comparePrice) * 100 : 0
		const formattedPrices: FormattedPrices = {
			priceString: PriceManager.formatPriceToCurrencyNoDecimal(priceCents! / 100, currency),
			comparePriceString: compareCents ? PriceManager.formatPriceToCurrencyNoDecimal(compareCents / 100, currency) : '',
			price: priceCents,
			comparePrice: compareCents,
			currency: currency,
		}
		return formattedPrices
	}

	public get appliedDiscount() {
		return this._appliedDiscount
	}

	public set appliedDiscount(discount: DiscountedVariants | undefined) {
		this._appliedDiscount = discount
	}

	public setAccessoriesDiscount(bool: boolean) {
		this.shouldDiscountAccessories = bool
	}

	public discountCartItem(item: CartLineItem, discounts: CurrencyDiscount, globalDiscounts: CurrencyDiscount | null, currency: Currency) {
		if (this.shouldDiscountAccessories && Collection10PercentOff.includes(item.productId)) {
			return discountCartLineByPercent(item, 10)
		}
		if (this.shouldDiscountAccessories && Collection20PercentOff.includes(item.productId)) {
			return discountCartLineByPercent(item, 20)
		}
		if (this.shouldDiscountAccessories && Collection25PercentOff.includes(item.productId)) {
			return discountCartLineByPercent(item, 25)
		}
		if (this.shouldDiscountAccessories && Collection30PercentOff.includes(item.productId)) {
			return discountCartLineByPercent(item, 30)
		}

		if (!discounts) {
			return item
		}

		const newItem = {
			...item,
		}
		const promoStoreDiscount = discounts[currency]
		const siteWideDiscount = globalDiscounts && globalDiscounts[currency]
		let discountAmount = promoStoreDiscount

		if (siteWideDiscount && !promoStoreDiscount) {
			discountAmount = siteWideDiscount
		}

		if (siteWideDiscount && discountAmount && siteWideDiscount > discountAmount) {
			discountAmount = siteWideDiscount
		}

		if (!discountAmount) {
			return item
		}

		if (typeof discountAmount === 'number') {
			newItem.price = item.price - discountAmount * 100
		} else {
			const percentOff = parseInt(discountAmount.substr(0, 2)) / 100
			newItem.price = item.price * (1 - percentOff)
		}
		newItem.comparePrice = item.comparePrice ? item.comparePrice : item.price
		return newItem
	}

	public applyDiscountToAll(discountCode: string, globalCode: string | undefined, region: RegionShort): ProductPriceMap {
		this.promoDiscountCode = discountCode
		const pricesCopy = cloneDeep(this.productPrices) as ProductPriceMap

		let globalDiscount: DiscountedVariants | undefined = undefined
		if (globalCode) {
			globalDiscount = discountCodes[globalCode]
		}

		const applyDiscountCodeSavings = () => {
			const discountedVariants = discountCodes[discountCode]

			for (const variantId in discountedVariants) {
				const variantDiscounts = discountedVariants[variantId]
				const variant = pricesCopy[variantId]

				if (variant) {
					let finalDiscount = variantDiscounts
					const currentGlobalDiscount = globalDiscount ? globalDiscount[variantId] : undefined
					if (currentGlobalDiscount && variantDiscounts && currentGlobalDiscount['USD']! > variantDiscounts['USD']!) {
						if (isPodBedVariantId(variantId)) {
							const mattressId = region === 'ca' ? MattressProducts.ThreeLayer.variants['caqueen'].id : MattressProducts.FiveLayerDiscounted.variants['queen'].id
							const coverId = PodCoverProducts.Pod3CoverPerfect.variants['queen'].id

							const currentGlobalCoverSale = (discountCodes[globalCode]?.[coverId]?.USD as number) ?? 0
							const currentGlobalMattressSale = (discountCodes[globalCode]?.[mattressId]?.USD as number) ?? 0
							const totalGlobalPodSale = currentGlobalCoverSale + currentGlobalMattressSale

							const currentVariantCoverSale = (discountedVariants[coverId]?.USD as number) ?? 0
							const currentVariantMattressSale = (variantDiscounts['USD']! as number) ?? 0
							const totalVariantPodSale = currentVariantCoverSale + currentVariantMattressSale

							if (totalGlobalPodSale > totalVariantPodSale) {
								finalDiscount = currentGlobalDiscount
							}
						} else {
							finalDiscount = currentGlobalDiscount
						}
					}
					variant.prices = this.subtractDiscountInCurrencyMap(variant.prices, finalDiscount)
				}
			}
		}

		const applyAccessoriesSavings = () => {
			if (!this.shouldDiscountAccessories) {
				return
			}
			for (const variantId of Collection10PercentOffVariants) {
				const variant = pricesCopy[variantId]
				if (variant) variant.prices = discountCurrencyMapByPercent(variant.prices, 10)
			}
			for (const variantId of Collection20PercentOffVariants) {
				const variant = pricesCopy[variantId]
				if (variant) variant.prices = discountCurrencyMapByPercent(variant.prices, 20)
			}
			for (const variantId of Collection25PercentOffVariants) {
				const variant = pricesCopy[variantId]
				if (variant) variant.prices = discountCurrencyMapByPercent(variant.prices, 25)
			}
			for (const variantId of Collection30PercentOffVariants) {
				const variant = pricesCopy[variantId]
				if (variant) variant.prices = discountCurrencyMapByPercent(variant.prices, 30)
			}
		}

		applyDiscountCodeSavings()
		applyAccessoriesSavings()

		return pricesCopy
	}

	public withPrices(product: ProductCore, prices: ProductPriceMap, currency: Currency): Product {
		let centsToIncrease = 0
		if (product.custom?.isCoverMembershipBundle) {
			centsToIncrease = 18000
		}
		return {
			...product,
			variants: this.addPricestoVariantList(product.variants, prices, centsToIncrease, currency),
		}
	}

	private addPricestoVariantList(variants: { [key: string]: VariantCore }, _prices: ProductPriceMap, centsToIncrease: number | null = null, currency: Currency) {
		let prices = _prices
		if (!Object.keys(prices).length) {
			prices = this.productPrices // fallback to default
		}
		const result: { [key: string]: ProductVariant } = {}

		let variantName: keyof typeof variants
		for (variantName in variants) {
			const _v = variants[variantName]

			const variantPrices = prices[_v.id]?.prices ?? {
				USD: {
					price: '1000 ¤',
					comparePrice: '1000 ¤',
				},
			}
			const localPrices = variantPrices[currency]
			let priceCents = localPrices?.price ? parseFloat(localPrices.price) * 100 : 0
			let compareCents = localPrices?.comparePrice ? parseFloat(localPrices.comparePrice) * 100 : 0

			if (centsToIncrease) {
				priceCents = priceCents ? priceCents + centsToIncrease : 0
				compareCents = compareCents ? compareCents + centsToIncrease : 0
			}

			const formattedPrices: FormattedPrices = {
				priceString: PriceManager.formatPriceToCurrencyNoDecimal(priceCents! / 100, currency),
				comparePriceString: compareCents ? PriceManager.formatPriceToCurrencyNoDecimal(compareCents / 100, currency) : '',
				price: priceCents,
				comparePrice: compareCents,
				currency: currency,
			}

			result[variantName] = { prices: formattedPrices, ..._v, key: variantName }
		}
		return result
	}

	public static usesVAT(currency: Currency) {
		return currency === 'GBP' || currency === 'EUR' || currency === 'SEK' || currency === 'DKK'
	}

	public static getRegionDefaultSizeName(region: RegionShort) {
		switch (region) {
			case 'eu':
				return 'EU Large Double'
			case 'se':
				return 'EU Large Double'
			case 'dk':
				return 'EU Large Double'
			case 'uk':
				return 'UK King'
			case 'au':
				return 'AU Queen'
			default:
				return 'Queen'
		}
	}

	public static getRegionDefaultVariant(region: RegionShort): VariantKey {
		switch (region) {
			case 'eu':
			case 'se':
			case 'dk':
				return 'eulargedouble'
			case 'uk':
				return 'ukking'
			case 'au':
				return 'auqueen'
			default:
				return 'queen'
		}
	}

	public static getRegionSubscriptionPrice(subscription_id: SubscriptionKeys, region: RegionShort): string {
		return this.formatPriceToRegionNoDecimal(SUBSCRIPTION_REGION_MAP[subscription_id][region], region)
	}

	public static getCurrencySubscriptionPrice(subscription_id: SubscriptionKeys, currency: Currency): string {
		return this.formatPriceToCurrencyNoDecimal(SUBSCRIPTION_REGION_MAP[subscription_id][this.getCurrencyRegion(currency)], currency)
	}

	public static getRegionSubscriptionPriceYearly(subscription_id: SubscriptionKeys, region: RegionShort): string {
		return this.formatPriceToRegionNoDecimal(SUBSCRIPTION_REGION_MAP[subscription_id][region] * 12, region)
	}

	public static getCurrencySubscriptionPriceYearly(subscription_id: SubscriptionKeys, currency: Currency): string {
		return this.formatPriceToCurrencyNoDecimal(SUBSCRIPTION_REGION_MAP[subscription_id][this.getCurrencyRegion(currency)] * 12, currency)
	}

	public static getRegionSubscriptionPriceDaily(subscription_id: SubscriptionKeys, region: RegionShort): string {
		return this.formatPriceToCurrency(SUBSCRIPTION_REGION_MAP[subscription_id][region] / 30, this.getRegionCurrency(region))
	}

	public static getCurrencySubscriptionPriceDaily(subscription_id: SubscriptionKeys, currency: Currency): string {
		return this.formatPriceToCurrency(SUBSCRIPTION_REGION_MAP[subscription_id][this.getCurrencyRegion(currency)] / 30, currency)
	}

	public static convertToCurrency(amount: number, currency: Currency) {
		if (VARIABLE_CURRENCY_MAP[currency] && VARIABLE_CURRENCY_MAP[currency][amount]) {
			return VARIABLE_CURRENCY_MAP[currency][amount]
		}

		return amount
	}
}
