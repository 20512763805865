/*
  Note: if you add custom descriptions for products using the old productAtc make sure to wire 
  that component to use the customDescription otherwise the description will be empty. 
*/

import { BlanketProducts, BundleProducts, SheetProducts } from 'products/allProducts'

const podDescription = 'The only smart mattress with advanced cooling and heating technology designed to help you fall asleep faster and keep you asleep.'
const podProDescription =
	'The most advanced Pod yet introducing new Room Climate and Weather Response, a Comfort Blend Integrated Topper, GentleRise Wake Up Technology, and double the number of sensors enabling new Heart Rate Variability monitoring and a Daily Health Check report.'
const thermoCoverDescription =
	'Love your current mattress but having temperature issues? Upgrade your sleep by adding the Pod\'s patented thermoregulation technology to your current mattress. Plus, enjoy the best in seamless sleep and health tracking. <br/><br/> Zips on to any 10" to 16" tall mattress. <br/> <br/> '
const thermoCoverDescriptionWithSubscription =
	'Upgrade your sleep with award-winning thermoregulation technology, seamless sleep and health tracking, and the GentleRise alarm. <br />Maximize your sleep improvement with 1 year of 8+ Pro included. 8+ Pro adds personalized intelligence to your Pod, with Temperature Autopilot, Digital Coaching, Data Insights, Sleep Content, and more. <br /> <br />'
const podProMaxDescription =
	"The Pod Pro Max features everything in the Pod Pro plus a new MaxChill™ layer infused with silver, graphite and copper to offer maximum heat dissipation and antimicrobial properties that further enhance the Pod Pro's temperature regulation technology and help you maintain a thermo neutral environment while you sleep."
const bluewaveDescipription = 'Sleep better by blocking harmful blue light waves that disrupt your circadian rhythm.'
const melatoninDescription =
	'A bundle of supplements designed to improve your sleep fitness. Includes 30 day supply of Magnesium CitraMate, Melaton-3, and RecoveryPro. Offered in collaboration with Thorne, backed by their comprehensive testing, the highest quality ingredients, and scientific research.'
const podProtectorDescription =
	'The ultimate mattress protection for your Pod. Made of TENCEL® jersey blend. Works over the Active Grid and under your bed sheets to shield the Pod from dust, pet dander, allergens, bacteria, mold, and liquid spills.'
// const gravityBlanketDescription = 'Relax before bedtime with our limited edition Gravity weighted blanket - designed exclusively for Eight Sleep. Reduce stress and anxiety, fall asleep faster, and sleep deeper after spending time under its weight.';
const gravityBlanketDescription = 'Relax before bedtime with a Gravity weighted blanket. Reduce stress and anxiety, fall asleep faster, and sleep deeper after spending time under its weight.'
const podFoundationDescription =
	'Designed to the fit any Pod, the Foundation eliminates the need for a box spring. Its slatted design is engineered with the strongest spruce for utmost support while you sleep.'
const sleepEssentialsDescription =
	'Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Carbon Air Pillows. Save $150 by buying these items together.'
const carbonPillowDescription =
	'Premium carbon-infused foam meets a proprietary phase-change material to absorb and dissipate heat away from the body. Its air ventilation system, encased in a high-quality TENCEL mesh cover, increases airflow and breathability.'
const podSheetSetDescription =
	'Exclusive Sheet Set designed specifically to fit your Pod. Made of 100% bamboo for maximum breathability so your body can feel the temperature effects of the Pod immediately.'
const podSheetSetMaloufDescription =
	'Premium Sheet Set from Malouf that perfectly fits your Pod. Made of 100% bamboo for maximum breathability so your body can feel the temperature effects of the Pod immediately. <br /><br />Malouf is a leader in premium bedding products. These sheets have been tested and approved by the Eight Sleep team to ensure a great fit and lasting quality.'
const podSheetSetGrayDescription =
	'Exclusive Sheet Set designed specifically to fit your Pod. Made of 100% Eucalyptus fibers for maximum breathability so your body can feel the temperature effects of the Pod immediately.'
const podProCoverOnlyUpgradeDescription =
	'Update your sleep experience with the Pod Pro technology, the most advanced yet. Built on the success of the original Pod, it now incorporates new features like premium Comfort Blend™, HRV tracking, personal health report, and GentleRise™ Wake Up Technology. Includes only the Active Grid and Hub (no Mattress).'
const airLiteComforterDescription =
	'Our favorite comforter, the ideal partner for your Pod sleep experience. The Air Lite Comforter will keep you cool and comfortable all night long. Made out of Hypoallergenic Down Alternative fill, it is soft to the touch and breathable. Pair it with your Pod and the Pod Sheet Set for an elevated sleep experience every night.'
const lavenderSprayDescription =
	'Enhance your sleep environment with this Lavender Aromatherapy spray designed to promote relaxation. Made with no artificial scents or colors, this natural scent can be sprayed on pillows, sheets, and comforters to help you naturally relax and fall asleep faster.'
const hypervoltPlusDescription =
	"Hyperice's Hypervolt Plus uses percussion therapy to relieve muscle soreness and stiffness to improve range of motion and promote circulation. The massage gun comes with 5 interchangeable head attachments to adjust your massage experience and target various muscle groups. Use it before you get into your Pod to relax your muscles and achieve optimal recovery during the night or wake your muscles up to start your day."
const comfortBundleDescription = 'Everything you need to pair with you Pod: two Carbon Air Pillows, one Air Lite Comforter, and one Sheet Set. Save $150 by buying these items together.'
const priceBedframeDescription =
	'Enhance your sleep experience with the modern Prince Bed Frame from Modloft. With a five foot upholstered channeled headboard in white eco leather and a solid pine platform, this foundation will fit your Pod perfectly and elevate your room’s design. No box spring necessary. <br/><br/>Modloft is a leader in creating high quality statement pieces that are consciously designed and priced. Eight Sleep is proud to partner with Modloft to bring you this high quality bed frame for your Pod. The foundation has been tested and approved by the Eight Sleep team to ensure its high quality.'
const venomBackDescription =
	"Hyperice's Venom Back is a digital wearable device that combines heat and vibration to warm up and loosen sore muscles. Simply put it around your torso and set the heat levels and vibration patterns at your personal preference to warm up your back before you get into your Pod at night."
const legPulseDescription =
	"The NormaTec 2.0 Compression Recovery Boots increase circulation to reduce pain and soreness in the legs and help you recover quickly. NormaTec's Patented PULSE technology with Zone Boost allows you to give targeted attention where you need it most."
const podProCoverOriginalDescription =
	"Love your current mattress but having temperature issues? Upgrade your sleep by adding the Pod's patented thermoregulation technology to your current mattress. Plus, enjoy the best in seamless sleep and health tracking. <br/><br/> Zips on to any ${minMattressDepth} to ${mediumMattressDepth} tall mattress.  <br/> <br/> "
const mattressMemberDescription =
	"Our best-selling Max mattress was designed to provide the maximum comfort when paired with the Pod Cover. Features a new MaxChill™ layer infused with silver, graphite and copper to offer maximum heat dissipation and antimicrobial properties that further enhance the Pod Pro's temperature regulation technology and help you maintain a thermo neutral environment while you sleep."
const productDescriptions = {
	'the-pod': podDescription,
	'the-pod-1': podDescription,
	'the-pod-pay-now': podDescription,
	'the-pod-march-promo-100-off': podDescription,
	'the-pod-standard-comfort-march-promo': podDescription,
	'the-pod-standard-comfort': podDescription,
	'the-blue-wave-glasses': bluewaveDescipription,
	'the-pod-protector': podProtectorDescription,
	'the-pod-protector-members': podProtectorDescription,
	'simple-foundation': podFoundationDescription,
	// 'the-ultimate-pillow'
	'sleep-fitness-kit': melatoninDescription,
	'the-carbon-air-pillow': carbonPillowDescription,
	'the-pod-pro-cover': thermoCoverDescription,
	'the-pod-pro-cover-perfect': thermoCoverDescription,
	'pod-pro-cover-lease': podProCoverOriginalDescription,
	'pod-pro-cover-perfect-lease': thermoCoverDescription,
	// 'the-sleep-essentials-bundle': sleepEssentialsDescription,
	'the-pod-pro-memorial-day-discount': podProDescription,
	'the-pod-pro': podProDescription,
	'pod-pro': podProDescription,
	'pod-pro-discounted-for-member-upgrade': podProDescription,
	'the-pod-pro-thermo-cover': podProCoverOnlyUpgradeDescription,
	'air-lite-comforter': airLiteComforterDescription,
	'air-lite-comforter-members': airLiteComforterDescription,
	'lavender-sleep-spray': lavenderSprayDescription,
	'hypervolt-plus': hypervoltPlusDescription,
	'comfort-bundle': comfortBundleDescription,
	'prince-bedframe': priceBedframeDescription,
	'the-pod-pro-cover-tim': thermoCoverDescription,
	'the-pod-pro-cover-pomp': thermoCoverDescription,
	'the-pod-pro-cover-1': thermoCoverDescription,
	'eight-sleep-x-hyperice-venom-back': venomBackDescription,
	'normatec-leg-pulse-2-0': legPulseDescription,
	'pod-pro-max-mattress-only': mattressMemberDescription,
	'pod-pro-max-mattress-only-1': mattressMemberDescription,
}

productDescriptions[SheetProducts.SheetSet.handle] = podSheetSetMaloufDescription
productDescriptions[SheetProducts.SheetSetGray.handle] = podSheetSetGrayDescription
productDescriptions[BundleProducts.SleepEssentialBundle.handle] = sleepEssentialsDescription
productDescriptions[BlanketProducts.BrandedGravity.handle] = gravityBlanketDescription

const sizeSelectionDescriptions = {
	'the-carbon-air-pillow': [
		{ details: '1x Carbon Air Pillow', badge: '' },
		{ details: '2x Carbon Air Pillows', badge: 'Most Popular' },
	],
	'the-carbon-air-pillow-king': [
		{ details: '1x Carbon Air Pillow - King', badge: 'New' },
		{ details: '2x Carbon Air Pillows - King', badge: 'New' },
	],
	'the-carbon-air-pillow-discounted': [
		{ details: '1x Carbon Air Pillow', badge: '' },
		{ details: '2x Carbon Air Pillows', badge: 'Most Popular' },
	],
	'the-carbon-air-pillow-king-discounted': [
		{ details: '1x Carbon Air Pillow - King', badge: 'New' },
		{ details: '2x Carbon Air Pillows - King', badge: 'New' },
	],
}

const exportDescriptions = {
	productDescriptions: productDescriptions,
	podDescription: podDescription,
	podProDescription: podProDescription,
	podProMaxDescription: podProMaxDescription,
	sizeSelectionDescriptions: sizeSelectionDescriptions,
	thermoCoverDescription: thermoCoverDescription,
	thermoCoverDescriptionWithSubscription: thermoCoverDescriptionWithSubscription,
	podProCoverOriginalDescription: podProCoverOriginalDescription,
	mattressMemberDescription: mattressMemberDescription,
}

export default exportDescriptions
