import { amAppError } from 'events/amplitude'
import React from 'react'
import styles from './ErrorBoundary.module.scss'

const sliceErrorStack = (stackTrace = '', numLines = 10) => {
	const lines = stackTrace.split('\n')
	const firstNLines = lines.slice(0, numLines)
	const joinedLines = firstNLines.join('\n')
	return joinedLines
}

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// You can use your own error logging service here
		const log = error.message + ': ' + sliceErrorStack(errorInfo.componentStack)
		console.log(log)
		amAppError(log)
	}

	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className={styles.container}>
					<img
						className={`${styles.logo}`}
						src="https://eightsleep.imgix.net/logo_0c17cdd4-7930-4520-b7a2-41331b22b2e2.svg?v=1619637907"
						alt="Eightsleep logo"
					/>
					<h1>Oops, something unexpected happened!</h1>
					<p>If you continue experiencing this problem, please contact customer support via the chat icon in the bottom left of your screen.</p>
					<button
						type="button"
						onClick={() => {
							this.setState({ hasError: false })
							window.location.href = window.location.href.split('?')[0]
						}}
					>
						Try again?
					</button>
				</div>
			)
		}

		// Return children components in case of no error
		return this.props.children
	}
}

export default ErrorBoundary
