import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from 'stores'
import { PodModel, ProductVisual, ShopError, ShopManager, ShopProduct, ShopSelection } from './shopManager'
import { ProductCore, ProductVariant, VariantCore, VariantKey } from 'products/types'
import { Currency } from 'prices/types'
import { PriceManager } from 'prices'
import { BundleProducts, MattressProducts, MembershipProducts, Pod4Accessories, PodCoverProducts } from 'products/allProducts'
import { OFFERED_MEMBERSHIPS, createCartLineItem, getVariantDimensions, isPodBedProductId, isPodCoverProductId } from 'products/utils'
import { getErrorMessage } from 'components/WebEv/Shop/utils/errorMessages'
import { sendTrackingEvents } from 'components/WebEv/Shop/context/checkoutUtils'
import { Attribute, LineItem } from 'cart'
import { ModalKey } from 'components/Phantom/_shop/Modals/modalMapping'
import { getShippingText } from 'config/shipping_timelines'
import {
	SubscriptionResponse,
	SubscriptionType,
	determineSubscriptionType,
	getSubscriptionFeatureNiceName,
	getSubscriptionFeatures,
	getSubscriptionYearlyCost,
} from 'components/_utils/subscriptionUtils'
import { jwtDecode } from 'jwt-decode'
import { SelectionSummaryProps } from 'components/Phantom/_shop/SelectionSummary/types'

const currencyDefaultSizes: { [key in Currency]?: VariantKey } = {
	USD: 'queen',
	CAD: 'caqueen',
	AUD: 'auqueen',
	EUR: 'eusuperking',
	GBP: 'uksuperking',
	SEK: 'sesuperking',
	DKK: 'dksuperking',
}

interface ShopStoreType {
	podData: ShopProduct
	selectedVariant: VariantKey
	selectedSelections: { [key: string]: string }
	selectedUpsells: ProductCore[]
	images: ProductVisual[]
	selectionImageIndex: { [key: string]: number }
	summaryInfo: Partial<SelectionSummaryProps>
	stripeLineItems: LineItem[]
	checkout: (sourceId: string) => void
	handleSelection: (selectionKey: string, optionKey: string) => void
}

export class ShopStore implements ShopStoreType {
	constructor(private readonly rootStore: RootStore, private readonly shopManager: ShopManager) {
		makeObservable(this)
		this.setDefaultVariant()
		this.setSubscriptionInformation()
	}

	@observable podData: ShopProduct = undefined
	@observable selectedVariant: VariantKey = 'queen'
	@observable selectedSelections: { [key: string]: string } = {
		'pod-model': 'pod_4_ultra',
		'leg-kit': 'none',
		'auto-pilot': 'autopilot_enhanced',
	}
	@observable error: ShopError = null
	@observable activeModal: ModalKey = ''

	@observable subscriptionInformation: SubscriptionResponse | undefined
	@observable goingToCheckout = false

	@action setDefaultVariant() {
		this.selectedVariant = currencyDefaultSizes[this.rootStore.priceStore.currency]
	}

	@action setPodShopData() {
		const podData = this.getProduct()
		this.podData = this.getProduct()
		this.selectedSelections = { ...this.selectedSelections, 'pod-model': podData.product.id }
	}

	private getProduct() {
		const pod4 = this.getpodVariants(this.podModelSelected)
		return pod4
	}

	private addProductPrices(productCore: ProductCore) {
		const productPriceData = this.rootStore.priceStore.withPrices(productCore)
		const actualPrices: { [key in VariantKey]?: ProductVariant } = {}

		Object.values(productPriceData.variants).forEach((val) => {
			if (val.id && val.prices.price > 0) {
				actualPrices[val.key] = val
			}
		})

		productPriceData.variants = actualPrices

		const newProduct = {
			...productPriceData,
		}
		return newProduct
	}

	@action setSubscriptionInformation = (info?: SubscriptionResponse) => {
		let si = info
		if (!si && typeof window !== 'undefined') {
			const saved = sessionStorage.getItem('subscriptionInformation')
			si = saved ? JSON.parse(sessionStorage.getItem('subscriptionInformation')) : undefined
		}
		this.subscriptionInformation = si
		this.setPodShopData()
	}

	@computed get productInfo(): any {
		const price = this.podVariant.prices.price
		const comparePrice = this.podVariant.prices.comparePrice

		return {
			title: this.podData.product.name,
			price: price,
			comparePrice: comparePrice,
			priceString: PriceManager.formatPriceToCurrencyNoDecimal(price / 100, this.rootStore.priceStore.currency),
			comparePriceString: PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, this.rootStore.priceStore.currency),
			discount: {
				type: 'amount', //'amount' | 'percentage'
				value: 100,
			},
			// financingProvider: 'affirm', // 'affirm' | 'klarna' | 'none'
			currency: this.rootStore.priceStore.currency,
		}
	}

	@computed get selectedUpsells() {
		const products = Object.entries(this.selectedSelections).map((entry) => {
			const selectionId = entry[0]
			const optionId = entry[1]
			const selection = this.podData.selections.find((s) => s.id === selectionId)
			if (!selection) {
				return undefined
			}
			const option = selection.options.find((o) => o.id === optionId)
			if (!option) {
				return undefined
			}
			return option.data.product
		})
		return products.filter((x) => !!x)
	}

	@computed get totalPriceFinancing() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.shopTotal.price / 100), this.rootStore.priceStore.currency)
	}

	@computed get selectedPodVariantFinancing() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.podPrices.price / 100), this.rootStore.priceStore.currency)
	}

	@computed get summaryInfo(): Partial<SelectionSummaryProps> {
		const productName = `${this.podData.product.name} (${this.podVariant.name})`
		let warrantyText = '2-year warranty'

		if (this.selectedSelections['auto-pilot'] === 'autopilot_enhanced') {
			warrantyText = '5-year warranty'
		}

		return {
			price: this.shopTotal.priceString,
			alternatePrice: this.shopTotal.comparePriceString,
			savings: this.shopTotal.savingsAmountString,
			financingProvider: this.rootStore.priceStore.financingProvider,
			financingAmount: this.totalPriceFinancing,
			orderItems: [productName, ...this.selectedUpsells.map((u) => u.name)].join(' + '),
			buttonText: 'Checkout',
			shippingText: getShippingText(this.podVariant, this.rootStore.settingsStore.currentRegion),
			benefits: [
				{
					icon: 'ShieldCheckLight',
					text: warrantyText,
				},
				{
					icon: 'MoonLight',
					text: '30-night risk-free trial',
				},
				{
					icon: 'EightBoxLight',
					text: 'Free returns',
				},
				{
					icon: 'DeliveryLight',
					text: 'Free shipping',
				},
			],
		}
	}

	@computed get podPrices() {
		const price = this.podVariant.prices.price
		const comparePrice = this.podVariant.prices.comparePrice
		return {
			price,
			comparePrice,
		}
	}

	@computed get shopTotal() {
		const podPrice = this.podPrices
		const upsellPrices = this.upsellTotalPrices

		const price = podPrice.price + upsellPrices.price
		const comparePrice = podPrice.comparePrice + upsellPrices.comparePrice

		const priceString = PriceManager.formatPriceToCurrencyNoDecimal(price / 100, this.rootStore.priceStore.currency)
		const comparePriceString = PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, this.rootStore.priceStore.currency)

		const savingsAmount = comparePrice > price ? comparePrice - price : 0
		const savingsAmountString = PriceManager.formatPriceToCurrencyNoDecimal(savingsAmount / 100, this.rootStore.priceStore.currency) + ' off'

		return {
			price,
			comparePrice,
			priceString,
			comparePriceString,
			savingsAmount,
			savingsAmountString,
		}
	}

	@computed get upsellTotalPrices() {
		const upsellPrice = this.selectedUpsells.reduce((total, upsell) => {
			const upsellItem = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			const upsellItemPrice = upsellItem.prices.price
			return total + upsellItemPrice
		}, 0)
		const upsellComparePrice = this.selectedUpsells.reduce((total, upsell) => {
			const upsellItem = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			const upsellItemPrice = upsellItem.prices.comparePrice
			return total + upsellItemPrice
		}, 0)
		return {
			price: upsellPrice,
			comparePrice: upsellComparePrice,
			savings: upsellComparePrice > upsellPrice ? upsellComparePrice - upsellPrice : 0,
		}
	}

	@computed get stickyData(): any {
		const productName = `${this.podData.product.name} (${this.podVariant.name})`
		let warrantyText = '2-year warranty'

		if (this.selectedSelections['auto-pilot'] === 'autopilot_enhanced') {
			warrantyText = '5-year warranty'
		}

		return {
			title: [productName, ...this.selectedUpsells.map((u) => u.name)].join(' + '),
			strikePrice: this.shopTotal.comparePriceString,
			alternatePriceDesktop: this.shopTotal.priceString,
			alternatePriceMobile: this.shopTotal.comparePriceString,
			// discountText: 'Discount Text',
			open: true,

			price: this.shopTotal.priceString,
			alternatePrice: this.shopTotal.comparePriceString,
			savings: this.shopTotal.savingsAmountString,
			financingProvider: this.rootStore.priceStore.financingProvider,
			financingAmount: this.totalPriceFinancing,
			orderItems: [productName, ...this.selectedUpsells.map((u) => u.name)].join(' + '),
			buttonText: 'Checkout',
			shippingText: getShippingText(this.podVariant, this.rootStore.settingsStore.currentRegion),

			benefits: [
				{
					icon: 'ShieldCheckLight',
					text: warrantyText,
				},
				{
					icon: 'MoonLight',
					text: '30-night risk-free trial',
				},
				{
					icon: 'EightBoxLight',
					text: 'Free returns',
				},
				{
					icon: 'DeliveryLight',
					text: 'Free shipping',
				},
			],
		}
	}

	@computed get images(): ProductVisual[] {
		let imgs: ProductVisual[] = [...this.podData.product.images]
		for (const selection of this.podData.selections) {
			if (selection.images.length > 0) {
				imgs = imgs.concat(selection.images)
			}
		}

		const combinedUpsellNames = this.selectionKeys
			.filter((sel) => sel !== 'pod-model' && sel !== 'size' && sel !== 'auto-pilot')
			.map((n) => n.replace(/[-_]/g, ''))
			.sort()
		const pod = this.podData.product.id.replace(/[-_]/g, '').replace('coverperfect', '')

		const combinedImageName = [pod].concat(combinedUpsellNames).join('_')
		const imageurl = 'https://eightsleep.imgix.net/shop_combined_' + combinedImageName + '.png'

		imgs.push({
			type: 'image',
			data: {
				src: imageurl,
				alt: 'All items selected',
			},
		})
		return imgs
	}

	@computed get selectionImages(): { [id: string]: ProductVisual[] } {
		const selectionImgs = {
			'pod-model': this.podData.product.images,
		}
		for (const selection of this.podData.selections) {
			if (selection.id === 'pod-model') {
				continue
			}
			selectionImgs[selection.id] = selection.images
		}
		return selectionImgs
	}

	@computed get selectionImageIndex(): { [id: string]: number } {
		const indices: { [id: string]: number } = {
			'pod-model': 0,
			size: 1,
		}
		let currentIndex = 2
		let previousLength = 0
		for (const selection of this.podData.selections) {
			if (selection.id === 'pod-model' || selection.id === 'size') {
				continue
			}
			if (selection.images.length < 1) {
				indices[selection.id] = undefined
			} else {
				indices[selection.id] = currentIndex
			}
			previousLength = selection.images.length
			currentIndex = currentIndex + previousLength
		}
		return indices
	}

	@computed get modelSelections() {
		return this.podData.product.variants
	}

	@computed get selectionData() {
		return this.podData.selections
	}

	@action setSelection = (selectionKey: string, optionKey: string) => {
		this.selectedSelections[selectionKey] = optionKey
		this.setPodShopData()
	}

	@action handleSelection = (selectionKey: string, optionKey: string) => {
		/* ugly hack for checkboxes */
		if (optionKey === 'true') {
			optionKey = this.podData.selections.find((s) => s.id === selectionKey).options[1].id
		}
		if (optionKey === 'false') {
			optionKey = this.podData.selections.find((s) => s.id === selectionKey).options[0].id
		}
		/* end ugly hack, need to refactor at some point */

		this.setSelection(selectionKey, optionKey)
		if (selectionKey === 'pod-model') {
			this.setPodShopData()
			if (!(this.selectedSelections['size'] in this.podData.product.variants)) {
				this.setDefaultVariant()
			}
		}
		if (selectionKey === 'size') {
			this.selectedVariant = optionKey as VariantKey
			this.setPodShopData()
		}

		if (this.error && this.error.associatedSelectionId === selectionKey) {
			// TODO, there could be more finesse to be made here, depending on how we structure the error interface.
			//  Right now, the assumption is that if a selection is made on a section that has an error, we should probably remove the error.
			this.error = null
		}
	}

	@computed get podModelSelected() {
		return this.selectedSelections['pod-model'] as PodModel
	}

	@computed get applicableSelections(): { [x: string]: string } {
		const selections = { ...this.selectedSelections }
		if (selections['pod-model'] !== 'pod_4_ultra') {
			delete selections['leg-kit']
		}
		return selections
	}

	@computed get selectionKeys() {
		const selections = this.applicableSelections
		const items = Object.entries(selections).map((entry) => {
			const selectionId = entry[0]
			const optionId = entry[1]
			const selection = this.podData.selections.find((s) => s.id === selectionId)
			if (!selection) {
				return undefined
			}
			const option = selection.options.find((o) => o.id === optionId)
			if (!option) {
				return undefined
			}
			if (option.data.product) {
				return selectionId
			}
			return undefined
		})
		return items.filter((x) => !!x)
	}

	@action checkRequiredSelections = () => {
		const requiredSelectionIDs = this.podData.selections.filter((s) => s.required).map((s) => s.id)

		requiredSelectionIDs.push('size')

		const selectionsMade = Object.keys(this.selectedSelections)
		const missingSelections = requiredSelectionIDs.filter((id) => !selectionsMade.includes(id))

		if (missingSelections.length !== 0) {
			const missingSelection = missingSelections[0]
			const missingSelectionElement = document.getElementById(`selection-${missingSelection}`)
			if (missingSelectionElement) {
				missingSelectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
			this.error = { associatedSelectionId: missingSelection, errorText: `${getErrorMessage(missingSelection)}` }
			return false
		}

		return true
	}

	@computed get podVariant() {
		return this.podData.product.variants[this.selectedVariant]
	}

	@computed get stripeLineItems() {
		const podVariant = this.podVariant
		const podItem: LineItem = {
			quantity: 1,
			variantId: podVariant.id,
			attributes: [{ key: 'size', value: podVariant.name }],
		}
		const lines: LineItem[] = []

		for (const upsell of this.selectedUpsells) {
			const variant = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			const line: LineItem = {
				quantity: 1,
				variantId: variant.id,
				attributes: this.getLineItemAttributes(variant),
			}
			lines.push(line)
		}
		lines.push(podItem)
		return lines
	}

	@action checkout = (sourceId: string) => {
		const allRequiredSteps = this.checkRequiredSelections()
		if (!allRequiredSteps) return

		this.goingToCheckout = true

		const itemsToAdd: LineItem[] = this.stripeLineItems
		const itemsToRemove: LineItem[] = []

		const addedMembership = true
		if (this.rootStore.cartStoreNew.hasMembership) {
			const index = this.rootStore.cartStoreNew.getIndexOfCurrentMembership()
			const currentMembership = OFFERED_MEMBERSHIPS()[index]
			if (currentMembership) {
				itemsToRemove.push({
					variantId: currentMembership.variants.standard.id,
					sellingPlanId: currentMembership.sellingPlanId,
					quantity: 1,
					attributes: [],
				})
			}
		}

		// if (sessionStorage.getItem(SKIP_MEMBERSHIP_CHECKOUT_KEY) !== null && !state.giftMembership) {
		// 	itemsToAdd.pop()
		// 	addedMembership = false
		// }

		// if (state.giftMembership) {
		// 	sessionStorage.setItem('giftMembership', 'true')
		// }

		sendTrackingEvents(createCartLineItem(this.podVariant, this.podData.product, { addMembership: addedMembership }), sourceId)

		const isValidPod = isPodBedProductId(this.podData.product.id)
		const isValidCover = isPodCoverProductId(this.podData.product.id)
		const isValidPodOrCoverAndNotUpgrade = (isValidPod || isValidCover) && !this.rootStore.cartStoreNew.hasMemberUpgradeInCart

		this.rootStore.cartStoreNew.removeItems(itemsToRemove).then(() => {
			this.rootStore.cartStoreNew.addItems(itemsToAdd).then(() => {
				if (isValidPodOrCoverAndNotUpgrade && this.rootStore.cartStoreNew.hasMembership && this.rootStore.cartStoreNew.numCovers < 2 && this.rootStore.cartStoreNew.numPods < 2) {
					this.rootStore.cartStoreNew.redirectToCheckout()
				} else {
					this.rootStore.cartStoreNew.toggleCartOpen()
					this.goingToCheckout = false
				}
			})
		})
	}

	private getLineItemAttributes(variant: VariantCore) {
		const attributes: Attribute[] = []
		if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id) {
			attributes.push({ key: 'warranty', value: 'extended warranty' })
		}

		if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id || variant.id === MembershipProducts.AutopilotStandard.variants.standard.id) {
			if (this.subscriptionInformation?.email) {
				const authToken = localStorage.getItem('authToken') || ''
				let userId = ''
				try {
					const jwt = jwtDecode(authToken)
					userId = jwt?.sub || ''
					attributes.push({ key: 'upgrade_eight_user_id', value: userId })
				} catch (e) {
					console.error('Error decoding JWT', e)
				}
			}
		}
		return attributes
	}

	private getCoverVideo() {
		let video = 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Summer+Bedroom+Coverdrop+Video.mp4'
		let poster = 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/summer.jpg'
		if (this.rootStore.priceStore.currency === 'AUD') {
			// Southern hemisphere
			video = 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/37b62429230541f4a834aff6e94c6072.mp4'
			poster = 'https://eightsleep.imgix.net/Summer_Carousel.jpg?v=1699555274'
		}
		return {
			video,
			poster,
		}
	}

	private getPod4Ultra(): ShopProduct {
		const podData: ShopProduct = {
			product: {
				...this.addProductPrices(PodCoverProducts.Pod4Ultra),
				images: [
					{
						type: 'image',
						data: {
							src: 'https://eightsleep.imgix.net/pod4_ultra_2024_update2.png',
							alt: 'Pod 4 ultra comes with an ajustable base',
						},
					},
				],
			},
			selections: [this.getPodModelData(), this.getSizesData(), this.getLegKitUpsellData(), this.getAutoPilotUpsellData(), this.getEssentialBundleUpsellData(), this.getMattressUpsellData()].filter(
				(x) => !!x
			),
		}
		return podData
	}

	private getPod4(): ShopProduct {
		return {
			product: {
				...this.addProductPrices(PodCoverProducts.Pod4),
				images: [
					{
						type: 'image',
						data: {
							src: 'https://eightsleep.imgix.net/pod4_2024_shopmain.png',
							alt: 'The Pod Cover 3 Perfect Fit and Hub on an empty background',
						},
					},
				],
			},
			selections: [this.getPodModelData(), this.getSizesData(), this.getAutoPilotUpsellData(), this.getEssentialBundleUpsellData(), this.getMattressUpsellData()].filter((x) => !!x),
		}
	}

	private getPod3(): ShopProduct {
		return {
			product: {
				...this.addProductPrices(PodCoverProducts.Pod3CoverPerfect),
				images: [
					{
						type: 'image',
						data: {
							src: 'https://eightsleep.imgix.net/pod3_2024_shopmain.png',
							alt: 'The Pod Cover 3 Perfect Fit and Hub on an empty background',
						},
					},
				],
			},
			selections: [this.getPodModelData(), this.getSizesData(), this.getAutoPilotUpsellData(), this.getEssentialBundleUpsellData(), this.getMattressUpsellData()].filter((x) => !!x),
		}
	}

	public getpodVariants(podModel: PodModel) {
		let pod: ShopProduct
		if (podModel === 'pod_3_cover_perfect') {
			pod = this.getPod3()
		} else if (podModel === 'pod_4') {
			pod = this.getPod4()
		} else {
			pod = this.getPod4Ultra()
		}
		return pod
	}

	private getSizeImage = (): string => {
		if (this.rootStore.settingsStore.region === 'USA') {
			if (this.selectedSelections['pod-model'] === 'pod_3_cover_perfect') {
				switch (this.selectedVariant) {
					case 'full':
						return 'https://eightsleep.imgix.net/shop_pod3_mattress_size_full.png'
					case 'queen':
						return 'https://eightsleep.imgix.net/shop_pod3_mattress_size_queen.png'
					case 'king':
						return 'https://eightsleep.imgix.net/pod3_size_king.jpg'
					case 'caliking':
						return 'https://eightsleep.imgix.net/pod3_size_caliking.jpg'
					default:
						return 'https://eightsleep.imgix.net/shop_pod3_mattress_size_generic.png'
				}
			} else {
				switch (this.selectedVariant) {
					case 'full':
						return 'https://eightsleep.imgix.net/shop_pod4_mattress_size_full.png'
					case 'queen':
						return 'https://eightsleep.imgix.net/shop_pod4_mattress_size_queen.png'
					case 'king':
						return 'https://eightsleep.imgix.net/pod4_size_king.jpg'
					case 'caliking':
						return 'https://eightsleep.imgix.net/pod4_size_caliking.jpg'
					default:
						return 'https://eightsleep.imgix.net/shop_pod4_mattress_size_generic.png'
				}
			}
		} else {
			if (this.selectedSelections['pod-model'] === 'pod_3_cover_perfect') {
				return 'https://eightsleep.imgix.net/shop_pod3_mattress_size_generic.png'
			} else {
				return 'https://eightsleep.imgix.net/shop_pod4_mattress_size_generic.png'
			}
		}
	}

	private getSizesData(): ShopSelection {
		if (!this.podData) {
			return undefined
		}

		const sizeImage = this.getSizeImage()

		return {
			title: 'Pod Size',
			id: 'size',
			required: true,
			images: [
				{
					type: 'image',
					data: {
						src: sizeImage,
						alt: 'Pod cover',
					},
				},
			],
			subtitle: 'What size mattress will you be placing the Pod on?',
			modalButtons: [
				{
					modalKey: 'size-modal',
					text: 'Need help choosing a size?',
				},
			],
			options: Object.values(this.podData.product.variants).map((variant) => {
				return {
					id: variant.key,
					type: 'standard',
					data: {
						title: variant.name,
						description: variant.description,
						subtitleTop: getVariantDimensions(variant.key as VariantKey),
					},
				}
			}),
		}
	}

	@action selectModal = (modalKey: ModalKey) => {
		this.activeModal = modalKey
	}

	@action closeModal = () => {
		this.activeModal = ''
	}

	private getPodModelData(): ShopSelection {
		const pod3 = {
			id: 'pod_3_cover_perfect',
			type: 'standard',
			data: {
				title: 'Pod 3',
				subtitleTop: 'Cover + Hub',
				subtitleSmallest: 'Includes the below:',
				checklist: ['Previous generation technology'],
			},
		}

		const pod4 = {
			id: 'pod_4',
			type: 'standard',
			data: {
				title: 'Pod 4',
				badge: 'New',
				subtitleTop: 'Cover + Hub',
				subtitleSmallest: 'Includes the below:',
				checklist: ['2x more cooling power', 'Silent performance', 'Enhanced comfort', 'Tap to control'],
			},
		}

		const pod4ultra = {
			id: 'pod_4_ultra',
			type: 'standard',
			data: {
				title: 'Pod 4 Ultra',
				badge: 'New',
				subtitleTop: 'Cover + Hub + Base',
				subtitleSmallest: 'Everything in Pod 4, plus:',
				checklist: ['Sleeping, reading, and custom positioning', 'Snoring detection', 'Snoring mitigation'],
			},
		}

		const getModelOptions = () => {
			return [pod3, pod4, pod4ultra]
		}

		return {
			id: 'pod-model',
			title: 'Model',
			images: [],
			modalButtons: [
				{
					modalKey: 'model-modal',
					text: 'Need help choosing a model?',
				},
			],
			options: getModelOptions(),
		}
	}

	private toPriceString(unitAmount: number) {
		return PriceManager.formatPriceToCurrencyNoDecimal(unitAmount / 100, this.rootStore.priceStore.currency)
	}

	public getDisplayPricing(variant: ProductVariant, discountText?: string) {
		let discount = ''
		if (variant.prices.comparePrice && variant.prices.price < variant.prices.comparePrice) {
			const diff = variant.prices.comparePrice - variant.prices.price
			discount = this.toPriceString(diff) + ' off'
		}
		return {
			price: this.toPriceString(variant.prices.price),
			comparePrice: this.toPriceString(variant.prices.comparePrice),
			discountText: discountText ? discountText : discount,
		}
	}

	private getMembershipPricing(variant: ProductVariant) {
		return {
			yearly: this.toPriceString(variant.prices.price),
			daily: PriceManager.formatPriceToCurrency(variant.prices.price / 365 / 100, this.rootStore.priceStore.currency),
			monthly: PriceManager.formatPriceToCurrencyNoDecimal(variant.prices.price / 12 / 100, this.rootStore.priceStore.currency),
		}
	}

	private getLegKitUpsellData(): ShopSelection {
		const product = this.addProductPrices(Pod4Accessories.Pod4UltraLegKit)
		return {
			id: 'leg-kit',
			title: 'Optional leg kit',
			subtitle:
				"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12 piece leg kit to have it stand on its own.",
			images: [
				{
					type: 'image',
					data: {
						src: 'https://eightsleep.imgix.net/pod4_ultra_legkit_2024_2.png',
						alt: 'Leg Ket display',
					},
				},
			],
			modalButtons: [
				{
					modalKey: 'legkit-modal',
					text: 'Do I need a leg kit?',
				},
			],
			// displayPricing: this.getDisplayPricing(product.variants['standard']),
			options: [
				{
					id: 'none',
					type: 'standard',
					data: {
						title: 'No leg kit',
						subtitle: 'Keep your current bed frame.',
					},
				},
				{
					id: 'adjustable',
					type: 'standard',
					data: {
						product: product,
						title: 'Add leg kit',
						subtitleMedium: this.getDisplayPricing(product.variants['standard']).price,
						subtitle: 'Replace your current bed frame with the Base',
					},
				},
			],
		}
	}

	private getEssentialBundleUpsellData(): ShopSelection {
		const product = this.addProductPrices(BundleProducts.SleepEssentialBundle)
		if (Object.keys(product.variants).length < 1) {
			return undefined
		}
		const product2 = this.addProductPrices(BundleProducts.SleepEssentialBundleGray)
		if (Object.keys(product2.variants).length < 1) {
			return undefined
		}
		return {
			id: 'essentials-bundle',
			title: 'Add an accessory and save more',
			subtitle: 'Make the most of your sleep with extras designed for the Pod',
			images: [
				{
					type: 'image',
					data: {
						src: 'https://eightsleep.imgix.net/essentials_bundle_white_2024_update.png',
						alt: 'The Pod Cover with the Sleep Essentials Bundle',
					},
				},
			],
			displayPricing: this.getDisplayPricing(product.variants[this.selectedVariant]),
			options: [
				{
					id: 'none',
					type: 'standard',
					data: {
						title: 'No Bundle',
						// subtitleMedium: '',
					},
				},
				{
					id: 'white',
					type: 'standard',
					data: {
						title: 'Sleep Essentials Bundle',
						subtitle: 'Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Carbon Air Pillows.',
						product: product,
					},
				},
			],
		}
	}

	private getCurrentPlanData = () => {
		const currentPlan = determineSubscriptionType(this.subscriptionInformation)

		const currentBenefits = getSubscriptionFeatures(currentPlan)
		const yearlyCost = getSubscriptionYearlyCost(currentPlan)[this.rootStore.priceStore.currency]
		const free = yearlyCost === 0

		const showStandardPlan = currentPlan === SubscriptionType.OPTIONAL_ERA_CHURNED || currentPlan === SubscriptionType.NO_SUBSCRIPTION

		const currentPriceMonthly = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost / 12, this.rootStore.priceStore.currency)}/mo` : 'Included'
		const currentPrice = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost, this.rootStore.priceStore.currency)} billed annually` : ''

		return {
			currentBenefits,
			yearlyCost,
			showStandardPlan,
			currentPrice,
			currentPriceMonthly,
		}
	}

	private getAutoPilotUpsellData(): ShopSelection {
		const standard = this.addProductPrices(MembershipProducts.AutopilotStandard)
		const enhanced = this.addProductPrices(MembershipProducts.AutopilotEnhanced)

		const subscriptionType = determineSubscriptionType(this.subscriptionInformation)
		const hidePlanSection = subscriptionType === SubscriptionType.MANDATORY_ERA_ENHANCED || subscriptionType === SubscriptionType.VIP

		const currentData = this.getCurrentPlanData()

		if (hidePlanSection) {
			return undefined
		}

		const getStandardPlan = () => {
			if (Object.keys(currentData).length && !currentData.showStandardPlan) {
				return {
					id: 'autopilot_standard',
					type: 'standard',
					data: {
						subtitleMedium: currentData.currentPriceMonthly,
						subtitle: currentData.currentPrice,
						title: 'Current',
						checklist: currentData.currentBenefits.map((b) => getSubscriptionFeatureNiceName(b)),
					},
				}
			}

			return {
				id: 'autopilot_standard',
				type: 'standard',
				data: {
					subtitleMedium: this.getMembershipPricing(standard.variants['standard']).monthly + ' /mo',
					subtitleSmallest: this.getMembershipPricing(standard.variants['standard']).yearly + ' billed annually',
					title: 'Standard',
					checklist: ['Automatic temperature', 'Sleep & health reports', 'Vibration & thermal alarms', 'Snoring detection & mitigation', 'Bi-annual maintenance filter delivery'],
					product: standard,
				},
			}
		}

		return {
			id: 'auto-pilot',
			required: true,
			title: 'Autopilot',
			subtitle: 'Unlock the full features of the Pod with Autopilot. One plan for you and your partner.',
			images: [
				{
					type: 'image',
					data: {
						src: 'https://eightsleep.imgix.net/shop_autpilot_app_2024_2.png',
						alt: 'App showing autopilot',
					},
				},
			],
			modalButtons: [
				{
					modalKey: 'membership-modal',
					text: 'Need help choosing a plan?',
				},
			],
			options: [
				getStandardPlan(),
				{
					id: 'autopilot_enhanced',
					type: 'standard',
					data: {
						title: 'Enhanced',
						badge: 'Popular',
						subtitleMedium: this.getMembershipPricing(enhanced.variants['standard']).monthly + ' /mo',
						subtitleSmallest: this.getMembershipPricing(enhanced.variants['standard']).yearly + ' billed annually',
						checklist: [
							'Automatic temperature',
							'Sleep & health reports',
							'Vibration & thermal alarms',
							'Snoring detection & mitigation',
							'Bi-annual maintenance filter delivery',
							'Extended 5-year warranty',
						],
						product: enhanced,
					},
				},
			],
		}
	}

	private getMattressUpsellData(): ShopSelection {
		const fiveLayer = this.addProductPrices(MattressProducts.FiveLayerDiscounted)
		const threeLayer = this.addProductPrices(MattressProducts.ThreeLayer)

		const numVariantsFiveLayer = Object.keys(fiveLayer.variants).length
		const numVariantsThreeLayer = Object.keys(threeLayer.variants).length

		if (numVariantsFiveLayer + numVariantsThreeLayer < 1) {
			return undefined
		}

		const numLayers = numVariantsFiveLayer > 0 ? 5 : 3
		const product = numVariantsFiveLayer > 0 ? fiveLayer : threeLayer

		return {
			id: 'eight-mattress',
			title: 'Add a mattress and save more',
			subtitle: `With our ${numLayers}-layer mattress, you can achieve the perfect mix of temperature control and comfort.`,
			images: [
				{
					type: 'image',
					data: {
						src: 'https://eightsleep.imgix.net/mattress_white_2024.png',
						alt: 'Cover parts plus mattress',
					},
				},
			],
			modalButtons: [
				{
					modalKey: 'mattress-modal',
					text: 'Why add a mattress?',
				},
			],
			displayPricing: this.getDisplayPricing(product.variants[this.selectedVariant]),
			options: [
				{
					id: 'none',
					type: 'standard',
					data: {
						title: "I'll use my current mattress",
						features: ['Automatic temperature', 'Bi-annual maintenance', 'filter delivery'],
					},
				},
				{
					id: 'yes_mattress',
					type: 'standard',
					data: {
						title: `${numLayers}-layer Mattress`,
						subtitle: 'Achieve the perfect mix of temperature control and comfort',
						product: product,
						icons: [
							{
								image: 'https://eightsleep.imgix.net/firmess.svg?v=1699556649',
								text: 'Medium firmness with spinal support',
							},
							{
								image: 'https://eightsleep.imgix.net/bed_e25e7971-b257-4f35-9f45-cf5a2058b7f9.png?v=1699556649',
								text: '11” mattress height',
							},
							{
								image: 'https://eightsleep.imgix.net/vibration.png?v=1699556649',
								text: 'Pressure relieving materials',
							},
							{
								image: 'https://eightsleep.imgix.net/warm.png?v=1699556649',
								text: 'Excellent airflow for cooler sleep',
							},
						],
					},
				},
			],
		}
	}
}
