import { POD_PRICE_PLACEHOLDER, COVER_PRICE_PLACEHOLDER, DIFF_PRICE_PLACEHOLDER } from './default'
import { EmailSettings, SiteSettings, VanityData } from './types'

export const VANITY_SESSION_KEY = 'current_vanity'

const fillMessagesWithPrice = (settings: SiteSettings, price: string, regex: RegExp) => {
	settings.homeSettings.heroTitle = settings.homeSettings.heroTitle.replace(regex, price)
	settings.homeSettings.heroSubtitle = settings.homeSettings.heroSubtitle.map((desc) => desc.replace(regex, price))

	settings.saleName = settings.saleName.replace(regex, price)
	settings.savingsTag = settings.partnerName ? `${settings.partnerName} Exclusive` : settings.savingsTag.replace(regex, price)
	settings.memberaccessoriestitle = settings.memberaccessoriestitle.replace(regex, price)
	settings.memberaccessoriessubtitle = settings.memberaccessoriessubtitle.replace(regex, price)
	settings.homeHeroSubtitle = settings.homeHeroSubtitle.replace(regex, price)
	settings.coverlpherosubtitle = settings.coverlpherosubtitle.replace(regex, price)
	settings.mattresslpherosubtitle = settings.mattresslpherosubtitle.replace(regex, price)
	settings.shopCardTag = settings.shopCardTag?.replace(regex, price)
	settings.stickyCardTag = settings.stickyCardTag?.replace(regex, price)
	settings.saleShopSavingsTag = settings.saleShopSavingsTag?.replace(regex, price)
	if (settings?.heroSettings?.description) {
		settings.heroSettings.description = settings.heroSettings.description.map((desc) => desc.replace(regex, price))
	}

	if ('preFooterSettings' in settings && settings.preFooterSettings != null) {
		settings.preFooterSettings.title = settings.preFooterSettings.title.replace(regex, price)
		if (settings.preFooterSettings.paragraphs != null) {
			settings.preFooterSettings.paragraphs = settings.preFooterSettings.paragraphs.map((desc) => desc.replace(regex, price))
		}
	}

	Object.keys(settings.promoBar ?? {}).forEach((k) => {
		if (k === 'priority') {
			return
		}
		const key = settings.promoBar[k]
		if (key) {
			settings.promoBar[k]!.message = key.message.replace(regex, price)
		}
	})

	Object.keys(settings.emailCapSettings ?? {}).forEach((k) => {
		const key = k as keyof EmailSettings
		if (key && settings.emailCapSettings && settings.emailCapSettings[key]) {
			// @ts-ignore
			settings.emailCapSettings[key] = settings.emailCapSettings[key].replace(regex, price)
		}
	})

	if (settings.klaviyoProperties && settings.klaviyoProperties.discount) {
		settings.klaviyoProperties.discount = settings.klaviyoProperties.discount.replace(regex, price)
	}

	if (settings.klaviyoProperties && settings.klaviyoProperties.mattressDiscount) {
		settings.klaviyoProperties.mattressDiscount = settings.klaviyoProperties.mattressDiscount.replace(regex, price)
	}
}

export const fillMessagesWithPodPrice = (settings: SiteSettings, price: string) => {
	const regex = new RegExp(POD_PRICE_PLACEHOLDER, 'g')
	fillMessagesWithPrice(settings, price, regex)
}

export const fillMessagesWithCoverPrice = (settings: SiteSettings, price: string) => {
	const regex = new RegExp(COVER_PRICE_PLACEHOLDER, 'g')
	fillMessagesWithPrice(settings, price, regex)
}

export const fillMessagesWithDiffPrice = (settings: SiteSettings, price: string) => {
	const regex = new RegExp(DIFF_PRICE_PLACEHOLDER, 'g')
	fillMessagesWithPrice(settings, price, regex)
}

export const mergeVanityIntoSiteSettings = (siteSettings: SiteSettings, vanitySettings: VanityData) => {
	vanitySettings.page = vanitySettings.page?.replace('*', '')
	let home_settings = {
		...siteSettings.homeSettings,
		...(vanitySettings.heroSettings
			? {
					heroTitle: vanitySettings.heroSettings.title,
					heroSubtitle: vanitySettings.heroSettings.description ?? siteSettings.homeSettings.heroSubtitle,
			  }
			: null),
	}
	return { ...siteSettings, ...vanitySettings, endDate: '', promoCode: vanitySettings.promoCode || siteSettings.promoCode, ...{ homeSettings: home_settings } }
}

export const createVanitySettings = (vanityObj?: VanityData) => {
	if (process.browser) {
		if (vanityObj) {
			sessionStorage.setItem(VANITY_SESSION_KEY, JSON.stringify(vanityObj))
			sessionStorage.setItem('utm_medium', vanityObj.utm_medium)
		} else {
			const obj_json = sessionStorage.getItem(VANITY_SESSION_KEY)
			vanityObj = typeof obj_json === 'string' ? JSON.parse(obj_json) : null
		}
	}
	return vanityObj
}

export function setCookie(cname: string, cvalue: string, exdays: number, domain?: string) {
	const d = new Date()
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
	let expires = 'expires=' + d.toUTCString()
	let cdomain = 'domain=' + domain
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/' + (domain ? ';' + cdomain : '')
}

export function getCookie(cname: string) {
	let name = cname + '='
	let ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const CF_COUNTRY_MAP = {
	CA: 'ca',
	AU: 'au',
	GB: 'uk',
	SE: 'se',
	DK: 'dk',

	DE: 'eu',
	FR: 'eu',
	IT: 'eu',
	ES: 'eu',
	NL: 'eu',
	BE: 'eu',
	FI: 'eu',
}
