import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'
import { RootStore } from 'stores'
import { CartManager } from './cartManager'
import * as types from 'cart/types'
import { CartLineItem } from 'cart/types'
import { trackCartClose, trackInitiateCheckout, trackRemoveFromCart, trackUpdateCartItem } from 'events/index'
import { loadExternalScript, setPageScroll } from 'utils'
import { MembershipProducts } from 'products/allProducts'
import { PriceManager } from 'prices'
import { amAppCheckoutError, trackCartMembershipUpdate } from 'events/amplitude'
import { klaviyoTrackLatestCart } from 'events/klaviyo'
import Router from 'next/router'
import { convertShopifyToStripe } from 'stripe_lib/constants'
import { createCartLineItemFromLineItem, mapUrlToShop, OFFERED_MEMBERSHIPS } from '../products/utils'
import { RegionShort } from 'utils/internationalization'

export class NewCartStore {
	public static eventName = 'refreshCartPersisted'

	constructor(private readonly rootStore: RootStore, private readonly cartManager: CartManager) {
		makeObservable(this)
		this.eventHandler = this.eventHandler.bind(this)
		if (typeof window !== 'undefined') {
			window.addEventListener(NewCartStore.eventName, this.eventHandler)
			/**
			 * If browser back button was used, flush cache
			 * This ensures that user will always see an accurate, up-to-date view based on their state
			 * This was implemented to prevent the cart from getting stuck or showing stale data when navigating back from shopify
			 * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
			 * https://web.dev/bfcache/#update-stale-or-sensitive-data-after-bfcache-restore
			 * https://developer.mozilla.org/en-US/docs/Web/API/PageTransitionEvent/persisted
			 */

			// let hideInterstitial = () => {
			// 	this.setState({ showInterstitial: false })
			// }
			// hideInterstitial = hideInterstitial.bind(this)
			window.onpageshow = function (event) {
				setTimeout(() => window.dispatchEvent(new Event('refreshCartPersisted')), 500)
			}
		}
	}

	private eventHandler() {
		this.showInterstitial = false
		this.updatingCart = false
		// this.initCart().then()
	}

	@observable cart: types.Cart = CartManager.ZeroCart
	@observable isCartOpen = false
	@observable showInterstitial = false
	@observable updatingCart = false

	@action setCart = (cart: types.Cart) => {
		this.cart = cart
		this.cartManager.saveCart(cart)
	}

	@action initCart = async () => {
		this.updatingCart = true
		const cart = this.cartManager.getCart(this.rootStore.priceStore.countryCodeFromCurrency)

		runInAction(() => {
			this.setCart(cart)
			if (!this.cart.id && typeof window !== 'undefined') {
				window.localStorage.removeItem('cartId')
			}
			if (this.cart.createdAt) {
				const before = new Date(this.cart.createdAt)
				const now = new Date()
				const differenceDays = (now.getTime() - before.getTime()) / (1000 * 60 * 60 * 24)
				if (differenceDays > 30) {
					this.setCart(CartManager.ZeroCart)
				} else {
					runInAction(() => {
						this.syncShippingTimelines()
					})
				}
			}
			this.rootStore.priceStore.syncPrices()
			this.updatingCart = false

			if (this.checkForCurrentShopPageItemInCart()) {
				this.setCartOpen()
			}
		})
	}

	@action syncShippingTimelines = async () => {
		this.processSyncShippingTimelines()
	}

	processSyncShippingTimelines = async () => {
		this.cartManager.syncShippingTimelines(this.cart, this.rootStore.priceStore.countryCodeFromCurrency)
	}

	getIndexOfCurrentMembership = () => {
		if (!this.hasMembership) {
			return -1
		}

		const membershipID = this.cartManager.membershipID(this.cart)

		const offeredMemberships = OFFERED_MEMBERSHIPS()
		for (let i = 0; i < offeredMemberships.length; i++) {
			const offering = offeredMemberships[i]

			if (offering.variants.standard.id === membershipID) {
				return i
			}
		}

		return -1
	}

	private checkForCurrentShopPageItemInCart() {
		if (typeof window !== 'undefined' && window.location.pathname.includes('product/')) {
			const shop = mapUrlToShop(window.location.pathname)
			const shopData = this.rootStore.productStore.getShopPageProducts(shop)

			for (const product of shopData.products) {
				const variants = Object.values(product.variants)
				for (const variant of variants) {
					if (this.itemVariants.includes(variant.id)) {
						return true
					}
				}
			}
		}
		return false
	}

	@action toggleCartOpen = () => {
		if (this.isCartOpen) {
			trackCartClose()
			this.isCartOpen = false
		} else {
			this.setCartOpen()
		}
		setPageScroll(!this.isCartOpen)
	}

	@action closeCart = () => {
		this.isCartOpen = false
		setPageScroll(true)
	}

	@action setCartOpen = () => {
		this.isCartOpen = true
	}

	@action setShowInterstitial = (show: boolean) => {
		this.showInterstitial = show
	}

	getActiveCart = () => {
		return this.cart
	}

	@action addItems = async (lineItems: types.LineItem[]) => {
		loadExternalScript('https://tag.simpli.fi/sifitag/1d04a5e0-b517-013a-4abf-0cc47a1f72a4', 'ott_atc')
		if (this.cart.id && this.updatingCart) {
			return
		}
		this.updatingCart = true

		// Ensure that we never add two memberships to the cart
		let filteredLineItems = lineItems
		if (this.hasMembership) {
			filteredLineItems = lineItems.filter((item) => {
				return Object.values(MembershipProducts).every((product) => product.variants.standard.id !== item.variantId)
			})
		}

		const cart = await this.cartManager.addLineItemsToCart(this.cart, filteredLineItems, this.rootStore.priceStore.countryCodeFromCurrency)

		this.setCart(cart)
		this.rootStore.priceStore.syncPrices()
		this.updatingCart = false
		klaviyoTrackLatestCart(this.cartManager.getCartString(this.cart))

		runInAction(() => {
			this.syncShippingTimelines()
		})
	}

	@action removeItems = async (lineItems: types.LineItem[]) => {
		if (!lineItems.length) {
			return
		}
		if (!this.updatingCart) {
			this.updatingCart = true
			const cart = this.cartManager.removeLineItemsFromCart(this.cart, lineItems, this.rootStore.priceStore.countryCodeFromCurrency)
			runInAction(() => {
				this.setCart(cart)
				lineItems
					.filter((it) => it.id)
					.forEach((it) => {
						try {
							trackRemoveFromCart(it, PriceManager.formatPrices(this.rootStore.priceStore.getVariantPrices(it.variantId), this.rootStore.priceStore.currency))
						} catch (error) {
							console.error(error)
						}
					})
				this.rootStore.priceStore.syncPrices()
				this.updatingCart = false
				klaviyoTrackLatestCart(this.cartManager.getCartString(this.cart))
			})
		}
	}

	@action updateItems = async (lineItems: types.LineItem[], action: string) => {
		if (!this.updatingCart) {
			this.updatingCart = true
			const cart = this.cartManager.updateLineItemsInCart(this.cart, lineItems, this.rootStore.priceStore.countryCodeFromCurrency)
			runInAction(() => {
				this.setCart(cart)
				lineItems
					.filter((it) => it.id)
					.forEach((it) => {
						try {
							trackUpdateCartItem(it, PriceManager.formatPrices(this.rootStore.priceStore.getVariantPrices(it.variantId), this.rootStore.priceStore.currency), action)
						} catch (error) {
							console.error(error)
						}
					})
				this.rootStore.priceStore.syncPrices()
				this.updatingCart = false
			})
		}
	}

	@action swapPlan = async (plan: any) => {
		const allPlans = Object.values(MembershipProducts).map((o) => o.variants.standard.id)
		await this.removeItems(allPlans.map((id) => ({ variantId: id, quantity: 1, attributes: [] })))
		await this.addItems([
			{
				variantId: plan.variantId,
				quantity: 1,
				attributes: [],
				sellingPlanId: plan.sellingPlanId,
			},
		])
		trackCartMembershipUpdate(plan.title)
	}

	@computed get numItems() {
		return this.cartManager.getNumItems(this.cart)
	}

	@computed get itemNames() {
		return this.cartManager.getItemNames(this.getFullCartItems())
	}

	@computed get itemVariants() {
		return this.cartManager.getItemVariants(this.cart)
	}

	@computed get hasPodOrCover() {
		return this.cartManager.cartContainsPodOrCover(this.cart)
	}

	@computed get numPods() {
		return this.cartManager.getNumPods(this.getActiveCart())
	}

	@computed get numCovers() {
		return this.cartManager.getNumCovers(this.getActiveCart())
	}

	@computed get hasTechCover() {
		return this.cartManager.cartContainsCover(this.cart)
	}

	@computed get hasMembership() {
		return this.cartManager.cartContainsMembership(this.cart)
	}

	@computed get hasMemberUpgradeInCart() {
		return this.cartManager.cartContainsMemberUpgrade(this.cart)
	}

	@computed get numAccessories() {
		return this.numItems - this.numPodsAndCovers - this.numBundles
	}

	@computed get numBundles() {
		return this.cartManager.getNumBundles(this.cart)
	}

	@computed get numPodsAndCovers() {
		return this.cartManager.getNumPodsAndCovers(this.cart)
	}

	@computed get numPodsAndCoversLines() {
		return this.cartManager.getNumPodsAndCoversLines(this.cart)
	}

	getCartDiscountedMattresses() {
		return this.cartManager.getCartDiscountedMattresses(this.cart)
	}

	getCombinedCartItemsForAbTest() {
		return this.cartManager.getCombinedCartItemsForAbTest(this.getFullCartItems())
	}

	@computed get mostExpensivePodOrCoverInCart() {
		return toJS(this.cartManager.getMostExpensivePodOrCoverInCart(this.getFullCartItems()))
	}

	@computed get subtotal() {
		const fullCartItems = this.getFullCartItems()
		return fullCartItems.reduce((acc, item) => {
			return acc + item.comparePrice * item.quantity
		}, 0)
	}

	@computed get total() {
		const fullCartItems = this.getFullCartItems()
		return fullCartItems.reduce((acc, item) => {
			return acc + item.price * item.quantity
		}, 0)
	}

	getFullCartItems(): CartLineItem[] {
		const { priceStore } = this.rootStore

		return this.cart.lineItems
			.filter((item) => !!item)
			.map((item) => {
				return createCartLineItemFromLineItem(item, PriceManager.formatPrices(priceStore.getVariantPrices(item.variantId), priceStore.currency))
			})
			.filter((item) => !!item)
	}

	async createCheckoutAndRedirect(region: RegionShort) {
		const { cartStoreNew, promoStore, priceStore } = this.rootStore

		const lines = cartStoreNew.getFullCartItems().map((li) => ({ variantId: li.variantId, quantity: li.quantity, attributes: li.attributes }))
		const coupon = promoStore.discountCode

		const shopifyData = {
			lines,
			region,
			coupons: [...coupon.split(',')],
			currency: priceStore.currency,
			email: sessionStorage.getItem('customer_email'),
		}

		try {
			const stripeData = convertShopifyToStripe(shopifyData)
			localStorage.setItem('stripeInvoice', JSON.stringify(stripeData))
			localStorage.setItem('truemed_data', null)
			await Router.push({ pathname: '/' + region + (region ? '/checkout' : 'checkout') })
		} catch (error) {
			console.error(error)
		}
	}

	@action redirectToCheckout() {
		try {
			this.setShowInterstitial(true)
			this.updatingCart = true
			trackInitiateCheckout(this.getFullCartItems())

			const region = this.rootStore.settingsStore.currentRegion
			this.createCheckoutAndRedirect(region).then(() => {
				this.rootStore.shopStore.goingToCheckout = false
			})
		} catch (error) {
			amAppCheckoutError(`Error redirecting to checkout: ${error}`)
			this.rootStore.shopStore.goingToCheckout = false
		}
	}
}
