import { SiteSettings } from './types'

export const POD_PRICE_PLACEHOLDER = '%%%'
export const COVER_PRICE_PLACEHOLDER = '###'
export const DIFF_PRICE_PLACEHOLDER = '&&&'

export const default_settings: SiteSettings = {
	homeSettings: {
		heroTitle: 'Join the sleep fitness movement',
		heroSubtitle: ['Welcome a new season with an upgrade to your sleep and recovery'],
		heroButton: undefined,
		heroButtonLinkDestination: undefined,
	},
	utm_medium: '',
	utm_source: '',
	savingsTag: '',
	cartTimer: '',
	cartTimerSubtitle: '',
	cartLegalese: '*Subject to approval.',
	checkoutLegalese: '*Subject to approval.',
	saleName: 'Sleep Cool',
	promoCode: 'sleepcool',
	memberaccessoriestitle: 'Members save up to 20% off accessories',
	promoBar: {
		'*': {
			message: 'Sleep Cool Sale: Up to %%% off Pod',
			link: null,
		},
		'/member-referrals': {
			message: 'Invite a friend below. Give $200. Get $100.',
			link: null,
		},
	},
	startDate: null,
	endDate: null,
	activeTimer: false,
	homeHeroTitle: '',
	homeHeroSubtitle: '',
	homeHeroButton: '',
	bedPerfectedText: '',
	shopCardHeading: '',
	pod3herotitle: '',
	pod3herosubtitle: '',
	coverlpherotitle: '',
	coverlpherosubtitle: '',
	coverLPButton: '',
	compareHeading: '',
	mattresslpherotitle: '',
	mattresslpherosubtitle: '',
	mattressLPButton: '',
	comparepagetitle: '',
	memberaccessoriestagtitle: '',
	memberaccessoriessubtitle: '',
	stickybartext: '',
	hooxsubheading: '',
	hooxHeading: '',
	podProUpgradeShopPromoPill: '',
	podProUpgradeAccessoryCardSaveText: '',
	appPageSuperheading: '',
	appPageHeading: '',
}

export default default_settings
