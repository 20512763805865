import { makeObservable, observable } from 'mobx'
import { RootStore } from 'stores'
import { ProductManager } from './'
import { COVER_PRICE_PLACEHOLDER, POD_PRICE_PLACEHOLDER } from 'config/default'
import { ShopPages } from 'products/types'

export class ProductStore {
	constructor(private readonly rootStore: RootStore, public readonly productManager: ProductManager) {
		makeObservable(this)
	}

	@observable activeProduct = null

	/*
		This coverShop data has been turned into an observable because
		there is ab test that will render an additional product if active.
		All other product data can remain static.
	*/

	@observable coverShop = this.productManager.getCoverShopProducts(this.rootStore.settingsStore.currentRegion)

	public refreshProducts() {
		this.coverShop = this.productManager.getCoverShopProducts(this.rootStore.settingsStore.currentRegion)
		// this.rootStore.cartStoreNew.initCart().then() //TODO this is a bandage fix due to us retrieving the cart before the region is set
	}

	public getShopPageProducts(shop: ShopPages) {
		if (shop === 'cover') {
			return this.coverShop
		}

		const region = this.rootStore.settingsStore.currentRegion
		return this.productManager.getShopPageProducts(shop, region)
	}

	public getMemberAccessoriesProducts(page: string, membersQueryParam: string) {
		const region = this.rootStore.settingsStore.currentRegion
		const saleName = this.rootStore.settingsStore.settings.saleName
		const data = this.productManager.getPageProducts(page, region, saleName)
		data.forEach((section) =>
			section.items.forEach((it) => {
				it.buttonQuery = membersQueryParam
				it.productDescription = it.productDescription.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, this.rootStore.priceStore.pod4UltraDiscountFormatted))
				it.mobileDescription = it.mobileDescription?.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, this.rootStore.priceStore.pod4UltraDiscountFormatted))
				it.ctaText = it.ctaText
					?.replace(POD_PRICE_PLACEHOLDER, this.rootStore.priceStore.totalProductDiscountNumberFormatted)
					.replace(COVER_PRICE_PLACEHOLDER, this.rootStore.priceStore.pod4UltraDiscountFormatted)
			})
		)

		return data
	}

	public getAccessoriesProducts(page: string) {
		const region = this.rootStore.settingsStore.currentRegion
		const saleName = this.rootStore.settingsStore.settings.saleName
		return this.productManager.getPageProducts(page, region, saleName)
	}

	public regionSellsBundle() {
		const region = this.rootStore.settingsStore.currentRegion

		return region === ''
	}

	public regionSellsMattress() {
		const region = this.rootStore.settingsStore.currentRegion

		return region === 'ca' || region === ''
	}
}
