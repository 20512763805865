import { CaptureLocation } from 'utils/events'
export const fbEventsSSUrl = 'https://i.eight.sl/track/s2s'

import { extractRegionFromPath, RegionShort } from 'utils/internationalization'
import { amEcapSubmitError } from 'events/amplitude'

export const sendFBEvents = (events: any[]) => {
	const eventManagerTestID = sessionStorage.getItem('eventManagerTestID')
	return fetch(fbEventsSSUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		},
		body: JSON.stringify({ events, eventManagerTestID }),
	})
		.then((response) => {
			return response.json()
		})
		.catch((e) => {
			console.error(e)
		})
}

// changed to subscribe users to 2 lists: master and subcribe location
export async function subscribeToKlaviyo(email: string, captureLocation: CaptureLocation, otherProperties: any = null) {
	const region = extractRegionFromPath(window.location.pathname)
	// const fetchUrl = 'https://pz6e20kd92.execute-api.us-east-2.amazonaws.com/default/klaviyo_duplicate'
	const fetchUrl = '/api/klaviyo_email/'
	const body = {
		email,
		captureLocation,
		region,
		...otherProperties,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = await response.json()
		return {
			error: null,
			body: json,
		}
	} catch (e) {
		console.error(e)
		amEcapSubmitError(JSON.stringify(e))
		return {
			error: e.message,
			body: null,
		}
	}
}

export async function subscribeSMSToKlaviyo(email: string, phone: string, code: string, amount: string, region: RegionShort) {
	const fetchUrl = '/api/sms_signup/'
	const body = {
		email,
		phone,
		amount,
		code,
		region,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = await response.json()
		return json
	} catch (e) {
		console.error('error: ', e)
		return {
			error: e.message,
			text: null,
		}
	}
}

export async function sendPDPAbandonmentToKlaviyo(email: string, code: string, amount: string, region: RegionShort) {
	const fetchUrl = '/api/pdp_abandonment/'
	const body = {
		email,
		amount,
		code,
		region,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = await response.json()
		return {
			error: null,
			body: json,
		}
	} catch (e) {
		console.error('error: ', e)
		return {
			error: e.message,
			body: null,
		}
	}
}
