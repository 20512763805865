import { Cart, CartLineItem, LineItem, LineItemByExistingId } from './types'
import { StoreFront } from './storeFront'
import {
	Collection10PercentOff,
	Collection20PercentOff,
	Collection25PercentOff,
	Collection30PercentOff,
	isBundleProductId,
	isMembershipVariantId,
	isMemberUpgradeProductId,
	isMemberUpgradeVariantId,
	isPodBedProductId,
	isPodBedVariantId,
	isPodCoverProductId,
	isPodCoverVariantId,
} from 'products/utils'
import { MattressProducts, MembershipProducts } from 'products/allProducts'
import shippingTimelines, { getAllShippingTexts } from 'config/shipping_timelines'
import { CountryCode } from 'cart/types'
import { getSubscriptionCode } from 'eight_api/subscription'
import { toJS } from 'mobx'
import cloneDeep from 'lodash.clonedeep'
import { ProductCore } from 'products/types'

interface CartManagerType {
	getCart: () => Cart
	cartId: string | undefined
	addLineItemsToCart: (cart: Cart, lineItems: LineItem[], countryCode: CountryCode) => Promise<Cart>
	removeLineItemsFromCart: (cart: Cart, lineItems: LineItem[]) => Cart
	updateCartDiscount: (cart: Cart, discountCode: string) => Cart
}

export class CartManager implements CartManagerType {
	private _cartId: string | undefined
	public static ZeroCart: Cart = {
		createdAt: '',
		id: undefined,
		lineItems: [],
	}

	private static getCountryCartKey(countryCode: CountryCode) {
		return countryCode === 'US' ? 'cartId' : 'cartId' + countryCode
	}

	constructor(public readonly store: StoreFront) {
		this._cartId = CartManager.loadCartId()
	}

	public get cartId() {
		return this._cartId
	}

	private static loadCartId(countryCode: CountryCode = 'US') {
		if (typeof window !== 'undefined') {
			return window.localStorage.getItem(CartManager.getCountryCartKey(countryCode)) || undefined
		}
		return undefined
	}

	private saveCartId(cart: Cart, countryCode: CountryCode = 'US', buyNow = false) {
		if (process.browser && cart.id && !buyNow) {
			this._cartId = cart.id
			const key = CartManager.getCountryCartKey(countryCode)
			window.localStorage.setItem(key, cart.id)
		}
	}

	public static emptyCarts() {
		if (typeof window !== 'undefined') {
			Object.keys(localStorage).forEach((key) => {
				if (key.startsWith('cartId')) window.localStorage.removeItem(key)
			})
		}
	}

	public saveCart(cart: Cart) {
		this.store.setLocalCart(cart)
	}

	public getCart(countryCode: CountryCode = 'US') {
		const cartId = CartManager.loadCartId(countryCode)
		if (cartId) {
			return this.store.getCart(countryCode)
		}
		return CartManager.ZeroCart
	}

	public async addLineItemsToCart(cart: Cart, lineItems: LineItem[], countryCode: CountryCode = 'US', buyNow = false, clearCart = false) {
		if (cart.id && !clearCart) {
			return this.store.cartAddLineItems(cart.id, await this.addRequiredLineItemAttributes(lineItems), countryCode)
		} else {
			const cart = this.store.cartCreate(await this.addRequiredLineItemAttributes(lineItems), countryCode)
			this.saveCartId(cart, countryCode, buyNow)
			return cart
		}
	}

	public updateCartDiscount(cart: Cart, discount: string) {
		if (cart.id) {
			return this.store.cartUpdateDiscount(cart.id, discount)
		} else {
			return CartManager.ZeroCart
		}
	}

	public removeLineItemsFromCart(cart: Cart, lineItems: LineItem[], countryCode: CountryCode = 'US') {
		return this.store.cartDecreaseLineItems(cart, lineItems, countryCode)
	}

	public updateLineItemsInCart(cart: Cart, lineItems: LineItem[], countryCode: CountryCode = 'US') {
		const lineitemsById = lineItems.map((it) => {
			return {
				id: it.id,
				attributes: it.attributes,
				quantity: it.quantity,
			}
		})
		return this.store.cartUpdateLineItems(lineitemsById, countryCode)
	}

	public getItemNames(cartLineItems: CartLineItem[]) {
		return cartLineItems.map((item) => item.productTitle)
	}

	public getItemVariants(cart: Cart) {
		return cart.lineItems.map((item) => item.variantId)
	}

	public getNumItems(cart: Cart) {
		function itemCounter(aggregate: number, currentItem: LineItem) {
			return aggregate + currentItem.quantity
		}
		return cart.lineItems.reduce(itemCounter, 0)
	}

	public cartContainsPodOrCover(cart: Cart) {
		return cart.lineItems.some((item) => {
			return (isPodCoverVariantId(item.variantId) || isPodBedVariantId(item.variantId)) && item.quantity > 0
		})
	}

	public cartContainsCover(cart: Cart) {
		return cart.lineItems.some((item) => isPodCoverVariantId(item.variantId) && item.quantity > 0)
	}

	public cartContainsMembership(cart: Cart) {
		return cart.lineItems.some((item) => isMembershipVariantId(item.variantId) && item.quantity > 0)
	}

	public membershipID(cart: Cart) {
		const memberships = cart.lineItems.filter((item) => isMembershipVariantId(item.variantId) && item.quantity > 0)

		if (memberships.length > 0) {
			return memberships[0].variantId
		}
		return 0
	}

	public cartContainsMemberUpgrade(cart: Cart) {
		return cart.lineItems.some((item) => isMemberUpgradeVariantId(item.variantId) && item.quantity > 0)
	}

	public getNumPods(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (isPodBedProductId(item.productId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumCovers(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (isPodCoverVariantId(item.variantId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumPodsAndCovers(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (isPodCoverVariantId(item.variantId) || isPodBedVariantId(item.variantId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumPodsAndCoversLines(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			const isPod = isPodCoverVariantId(item.variantId) || isPodBedVariantId(item.variantId)
			if (item.quantity > 0 && isPod) {
				return aggregate + 1
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumBundles(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (isBundleProductId(item.productId) || isMemberUpgradeProductId(item.productId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumAccessoriesDiscount10OffCollection(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (Collection10PercentOff.includes(item.productId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getNumAccessoriesDiscountCollection(cart: Cart) {
		function counter(aggregate: number, item: CartLineItem) {
			if (Collection10PercentOff.includes(item.productId)) {
				return aggregate + item.quantity
			}
			if (Collection20PercentOff.includes(item.productId)) {
				return aggregate + item.quantity
			}
			if (Collection25PercentOff.includes(item.productId)) {
				return aggregate + item.quantity
			}
			if (Collection30PercentOff.includes(item.productId)) {
				return aggregate + item.quantity
			}
			return aggregate
		}
		return cart.lineItems.reduce(counter, 0)
	}

	public getMostExpensivePodOrCoverInCart(cartLineItems: CartLineItem[]) {
		let mostExpensivePodOrCoverInCart = null
		const lines = this.getCombinedCartItemsForAbTest(cartLineItems)
		for (const currentItem of lines) {
			const isPodFamily = isPodBedProductId(currentItem.productId) || isPodCoverProductId(currentItem.productId) || isMemberUpgradeProductId(currentItem.productId)
			if (isPodFamily) {
				if (!mostExpensivePodOrCoverInCart || mostExpensivePodOrCoverInCart.price < currentItem.price) {
					mostExpensivePodOrCoverInCart = currentItem
				}
			}
		}
		return mostExpensivePodOrCoverInCart
	}

	public cartHasVariant(cart: Cart, variantId: number) {
		return cart.lineItems.some((item) => item.variantId === variantId && item.quantity > 0)
	}

	public cartContainsProduct(cart: Cart, product: ProductCore) {
		const variantIds = Object.values(product.variants).map((variant) => variant.id)
		return cart.lineItems.some((item) => variantIds.includes(item.variantId) && item.quantity > 0)
	}

	public getCartDiscountedMattresses(cart: Cart) {
		const mattressVariantIds = [
			...Object.values(MattressProducts.FiveLayerDiscounted.variants).map((mattress) => mattress.id),
			...Object.values(MattressProducts.ThreeLayer.variants).map((mattress) => mattress.id),
		]
		return cart.lineItems.filter((lineItem) => mattressVariantIds.includes(lineItem.variantId))
	}

	public getCartString(cart: Cart) {
		const items = cart.lineItems.map((item) => `${item.variantId}:${item.quantity}`)
		return items.join(',')
	}

	private getTodaysTimelines(cart: Cart) {
		const lineItems = cart.lineItems
		const newLineItems: LineItemByExistingId[] = []
		let mostDays = 0
		let numTimelinesUS = 0
		let longestTimeline1: any
		let longestTimeline2: any

		for (const lineItem of lineItems) {
			const variantData = shippingTimelines[lineItem.variantId]
			const currentValue = lineItem.attributes.find((a) => a.key === 'shippingTimeline2')
			const currentTimeline = lineItem.attributes.find((a) => a.key === 'shippingTimeline')
			const currentDateRange = currentValue ? currentValue.value : JSON.stringify(null)
			const actualDateRange = JSON.stringify(getAllShippingTexts(variantData))
			const minDays = currentTimeline ? JSON.parse(currentTimeline?.value) : undefined

			if (minDays && minDays.minDaysUS && parseInt(minDays.minDaysUS) >= mostDays) {
				longestTimeline1 = JSON.stringify(minDays)
				longestTimeline2 = actualDateRange

				mostDays = parseInt(minDays.minDaysUS)
				numTimelinesUS = numTimelinesUS + 1
			}

			const li = cloneDeep(toJS(lineItem))
			const newItem = { quantity: li.quantity, id: li.id, attributes: li.attributes }
			newLineItems.push(toJS(newItem))
		}

		if (longestTimeline1) {
			for (const lineItem of newLineItems) {
				const currentTimeline = lineItem.attributes.find((a) => a.key === 'shippingTimeline')
				if (currentTimeline) {
					lineItem.attributes.find((a) => a.key === 'shippingTimeline').value = longestTimeline1
					const b = (lineItem.attributes.find((a) => a.key === 'shippingTimeline2').value = longestTimeline2)
				}
			}
		}

		return newLineItems
	}

	public async syncShippingTimelines(cart: Cart, countryCode: CountryCode) {
		const linesWithLatestDate: LineItemByExistingId[] = this.getTodaysTimelines(cart)

		if (linesWithLatestDate) {
			return this.store.cartUpdateLineItems(linesWithLatestDate, countryCode)
		} else {
			return cart
		}
	}

	public async addRequiredLineItemAttributes(lineItems: LineItem[]) {
		const lineItemUpdated: LineItem[] = []
		for (const lineItem of lineItems) {
			const variantData = shippingTimelines[lineItem.variantId]
			const timeline = JSON.stringify(variantData)
			const humanReadable = JSON.stringify(getAllShippingTexts(variantData))

			if (timeline) {
				lineItem.attributes.push({ key: 'shippingTimeline', value: timeline })
				if (humanReadable) {
					lineItem.attributes.push({ key: 'shippingTimeline2', value: humanReadable })
				}
			}

			const isMembership = isMembershipVariantId(lineItem.variantId)
			const isMemberProduct = sessionStorage.getItem('appliedDiscountCode') === 'members' || isMemberUpgradeVariantId(lineItem.variantId)

			if (isMemberProduct) {
				let hasOrderSource = false
				for (const attribute of lineItem.attributes) {
					if (attribute.key === 'order_source') {
						hasOrderSource = true
						break
					}
				}

				if (!hasOrderSource) {
					lineItem.attributes.push({ key: 'order_source', value: 'member' })
					if (sessionStorage.getItem('memberShopCode')) {
						lineItem.attributes.push({ key: 'shop_code', value: sessionStorage.getItem('memberShopCode')! })
					}
				}
			}

			if (isMembership) {
				let hasCode = false
				for (const attribute of lineItem.attributes) {
					if (attribute.key === 'code') {
						hasCode = true
						break
					}
				}

				if (!hasCode) {
					lineItem.attributes.push({ key: 'activation_code', value: await getSubscriptionCode() })
				}

				if (sessionStorage.getItem('giftMembership') === 'true') {
					lineItem.attributes.push({ key: 'gift', value: 'true' })
				}
			}

			lineItemUpdated.push(lineItem)
		}

		return lineItemUpdated
	}

	public getCombinedCartItemsForAbTest(cartItems: CartLineItem[]) {
		const eligibleCoverProducts = cartItems.filter((lineItem) => lineItem.attributes.some((attr) => attr.key === 'coverMembershipBundle'))
		const eligibleMemberships = cartItems.filter((lineItem) => lineItem.productId === MembershipProducts.EightPlusPro.id)

		if (eligibleCoverProducts.length > 0 && eligibleMemberships.length > 0) {
			const firstCoverId = eligibleCoverProducts[0].id
			const firstMembershipId = eligibleMemberships[0].id

			const firstCover = cartItems.find((item) => item.id === firstCoverId)!
			const firstMembership = cartItems.find((item) => item.id === firstMembershipId)!
			const allOtherItems = cartItems.filter((item) => !(item.id === firstCoverId || item.id === firstMembershipId))

			let totalCompare: number | null = null
			if (firstCover.comparePrice && firstMembership.comparePrice) {
				totalCompare = firstCover.comparePrice + firstMembership.comparePrice
			} else if (firstCover.comparePrice && !firstMembership.comparePrice) {
				totalCompare = firstCover.comparePrice + firstMembership.price
			}
			const combined = {
				...firstCover,
				price: firstCover.price + firstMembership.price,
				comparePrice: totalCompare,
				productTitle: 'Pod 3 Cover with 8+ Pro',
			}

			return [combined, ...allOtherItems]
		}
		return cartItems
	}
}
