import { sha256 } from 'js-sha256'

export const ottEmailConversion = (email: string) => {
	const hashedEmail = sha256(email)
	const elem = document.createElement('script')
	const protocol = document.location.protocol == 'https:' ? 'https://' : 'http://'
	const cb = Math.floor(Math.random() * 100000000)
	elem.src = protocol + 'u.fg8dgt.com/pixel?type=js&id=999&cust=' + hashedEmail + '&cb=' + cb
	elem.async = true
	elem.type = 'text/javascript'
	const scpt = document.getElementsByTagName('script')[0]
	scpt.parentNode!.insertBefore(elem, scpt)
}

export const ottPageView = () => {
	const elem = document.createElement('script')
	const protocol = document.location.protocol == 'https:' ? 'https://' : 'http://'
	const cb = Math.floor(Math.random() * 100000000)
	elem.src = protocol + 'u.fg8dgt.com/pixel?type=js&id=997&cb=' + cb
	elem.async = true
	elem.type = 'text/javascript'
	const scpt = document.getElementsByTagName('script')[0]
	scpt.parentNode!.insertBefore(elem, scpt)
}

export const ottSiteVisit = () => {
	const elem = document.createElement('script')
	const protocol = document.location.protocol == 'https:' ? 'https://' : 'http://'
	const cb = Math.floor(Math.random() * 100000000)
	elem.src = protocol + 'u.fg8dgt.com/pixel?type=js&id=998&cb=' + cb
	elem.async = true
	elem.type = 'text/javascript'
	const scpt = document.getElementsByTagName('script')[0]
	scpt.parentNode!.insertBefore(elem, scpt)
}

export const ottCheckoutInitiated = (variantIds: number[]) => {
	const elem = document.createElement('script')
	const protocol = document.location.protocol == 'https:' ? 'https://' : 'http://'
	const cb = Math.floor(Math.random() * 100000000)
	elem.src = protocol + 'u.fg8dgt.com/pixel?type=js&id=1000&sku=' + variantIds.join(',') + '&cb=' + cb
	elem.async = true
	elem.type = 'text/javascript'
	const scpt = document.getElementsByTagName('script')[0]
	scpt.parentNode!.insertBefore(elem, scpt)
}
