import { CartLineItem } from 'cart'

export const twPageView = () => {
	try {
		twq('event', 'tw-nw40a-ocwxn', {})
	} catch (e) {
		console.log(e)
	}
}

export const twAddToCart = (item: CartLineItem) => {
	try {
		// twq('event', 'tw-nw40a-o2a9m', {
		// 	value: item.price,
		// 	currency: item.prices.currency,
		// 	contents: [
		// 		{
		// 			content_id: item.variantId,
		// 			num_items: item.quantity,
		// 			content_name: item.productTitle,
		// 		},
		// 	],
		// })
		twq('event', 'tw-nw40a-o2a9m', {})
	} catch (e) {
		console.log(e)
	}
}

export const twInitiateCheckout = (items: CartLineItem[]) => {
	try {
		// const totalPrice = items.reduce((total, lineItem) => (total = total + lineItem.price), 0)
		// twq('event', 'tw-nw40a-o1zr9', {
		// 	value: totalPrice,
		// 	currency: items[0].prices.currency,
		// 	contents: items.map((item) => {
		// 		return {
		// 			content_id: item.variantId,
		// 			num_items: item.quantity,
		// 			content_name: item.productTitle,
		// 		}
		// 	}),
		// })
		twq('event', 'tw-nw40a-o1zr9', {})
	} catch (e) {
		console.log(e)
	}
}
