export default class ModalEvent {
	timer: any
	idleTimeMs: number

	constructor() {
		this.idleTimeMs = 180000
	}

	resetTimer = (callback: () => void, delay?: number) => {
		clearTimeout(this.timer)
		//making sure idleTimeMs is defined and higher then 3000
		// this is to prevent any 'infinite loops if this is put on mouseMove event listener.
		if (this.idleTimeMs) {
			this.timer = setTimeout(callback, delay || Math.max(this.idleTimeMs | 3000))
		}
	}
}
