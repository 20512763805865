import { action, computed, makeObservable, observable } from 'mobx'
import { mattressBlacklist, promoBlackList } from './utils'
import { PromoBar, PromoMessage } from 'config/types'
import { RootStore } from 'stores/index'
import { DiscountedVariants } from 'prices/types'
import { discountCodes } from 'promos'
import { getUrlPromoSettings } from 'config/url_promos'

export class PromoStore {
	constructor(private readonly rootStore: RootStore) {
		makeObservable(this)
	}

	@observable discountCode = ''
	@observable promoBarData = {}

	@observable promoBarTitle = ''
	@observable promoBarLink = ''
	@observable showTimer = true

	@action initPromo = () => {
		const sessionCode = sessionStorage.getItem('appliedDiscountCode')
		this.setPromo(sessionCode)
	}

	@action setPromo = (code: string | null) => {
		if (!code) {
			return
		}
		this.discountCode = code
		this.rootStore.priceStore.priceManager.appliedDiscount = this.discount
		this.rootStore.priceStore.syncPrices()

		if (typeof window !== 'undefined') {
			const appliedDiscountCode = sessionStorage.getItem('appliedDiscountCode')
			const urlPromoSettings = getUrlPromoSettings(window.location.search)
			if (!appliedDiscountCode || this.rootStore.settingsStore.isVanity || (appliedDiscountCode !== this.discountCode && urlPromoSettings)) {
				sessionStorage.setItem('appliedDiscountCode', this.discountCode)
			}
		}
	}

	@action setPromoBar = (currentPage: string) => {
		const { settings } = this.rootStore.settingsStore
		if (!settings) {
			return
		}
		const promoMessages = settings.promoBar
		if (promoMessages) {
			this.setPromoBarCurrentPath(promoMessages, currentPage)
		}
	}

	@action removePromo = () => {
		this.discountCode = ''
		sessionStorage.removeItem('appliedDiscountCode')
		localStorage.removeItem('vanity')
		this.rootStore.priceStore.priceManager.appliedDiscount = undefined
		this.rootStore.priceStore.syncPrices()
	}

	hasPromo(code: string) {
		return this.discountCode === code
	}

	@computed get discount(): DiscountedVariants | undefined {
		return discountCodes[this.discountCode]
	}

	private findExactMatch = (it: string, currentPage: string, currentPageWithSlash: string) => {
		return it === currentPage || it === currentPageWithSlash || it + '/' === currentPageWithSlash
	}

	private findSubStringMatch = (it: string, currentPage: string, currentPageWithSlash: string) => {
		return currentPage.endsWith(it) || currentPageWithSlash.endsWith(it)
	}

	private findPageKey = (promoMessages: PromoBar, currentPage: string, currentPageWithSlash: string) => {
		const promoMessageKeys = Object.keys(promoMessages)
		return promoMessageKeys.find((it) => this.findExactMatch(it, currentPage, currentPageWithSlash)) || promoMessageKeys.find((it) => this.findSubStringMatch(it, currentPage, currentPageWithSlash))
	}

	private getPromoDetails = (promoData: PromoMessage | undefined) => {
		const promoDetails = { message: '', link: '', showTimer: null }
		if (promoData) {
			promoDetails.message = promoData.message
			promoDetails.link = promoData.link?.to || ''
			promoDetails.showTimer = promoData.showTimer
		}
		return promoDetails
	}

	private normalizePath = (currentPage: string) => {
		const path = currentPage.split('?')[0]
		const trailingSlashRemoved = path.endsWith('/') ? path.slice(0, -1) : path
		return { normalizedPath: trailingSlashRemoved, pathWithSlash: trailingSlashRemoved + '/' }
	}

	@computed get vanityPreventsMattress() {
		return (
			mattressBlacklist.includes(this.discountCode) ||
			mattressBlacklist.includes(this.discountCode + '50') ||
			mattressBlacklist.includes(this.discountCode.toUpperCase()) ||
			mattressBlacklist.includes(this.discountCode.toUpperCase() + '50')
		)
	}

	@action setPromoBarCurrentPath = (promoMessages: PromoBar, currentPage: string) => {
		// We return null if no current page is provided, or if the current page
		// is found in the promotional blacklist
		if (!currentPage || promoBlackList.indexOf(currentPage) > -1) {
			return null
		}

		// We normalize the current page's path, removing any trailing slashes
		const { normalizedPath, pathWithSlash } = this.normalizePath(currentPage)

		// We look for an exact match with the normalized path within the promotional messages
		// Then, if we cannot find one, we look for a substring match
		const pageKey = this.findPageKey(promoMessages, normalizedPath, pathWithSlash)

		// If a promotional message exists for this specific page, it is assigned to pagePromo
		// otherwise, this variable is set to null
		const pagePromo = normalizedPath.length > 0 && pageKey ? promoMessages[pageKey] : null

		const sitePromo = promoMessages['*']

		const { message: siteMessage, link: siteLink, showTimer: siteTimer } = this.getPromoDetails(sitePromo)
		const { message: pageMessage, link: pageLink, showTimer: pageTimer } = this.getPromoDetails(pagePromo)

		// if page promotional details exist, they override the site promotional details
		const promoMessage = pageMessage || siteMessage
		const promoLink = pageLink || siteLink
		const showTimer = pageTimer != null ? pageTimer : siteTimer

		// TODO: consider refactoring to remove the ambiguity raised by !=
		// page/siteTimer is typically undefined, and in Javascript, `undefined == null`,
		// however, `undefined !== null`.

		// Updating the class fields
		this.promoBarTitle = promoMessage
		this.promoBarLink = promoLink
		this.showTimer = showTimer != null ? showTimer : true
	}
}
