// interface MetaData {
//   title: string;
//   description: string;
//   url: string;
//   metaimage: string;

import { vanityConfig } from 'config/vanity'

function createMetaData(title, description, image, url) {
	return {
		title: title,
		meta: [
			{ name: 'description', content: description },
			{ itemprop: 'name', content: title },
			{ itemprop: 'description', content: description },
			{ itemprop: 'image', content: image },
			{ property: 'og:url', content: url },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:title', content: title },
			{ property: 'og:description', content: description },
			{ property: 'og:image', content: image },
			{ name: 'twitter:card', content: 'summary_large_image' },
			{ name: 'twitter:title', content: title },
			{ name: 'twitter:description', content: description },
			{ name: 'twitter:image', content: image },
		],
	}
}

function generateMetaData(pagesList, title, description, image, url) {
	const meta = {}

	pagesList.forEach((pagePath) => {
		const pageData = createMetaData(title, description, image, url)
		meta[pagePath] = pageData
	})
	return meta
}

const podImage = 'https://eightsleep.imgix.net/image_386.jpg?v=1658486115'
const lifestyleImage = 'https://eightsleep.imgix.net/assets/homepage_meta.jpg'

export const metadata = {
	...generateMetaData(
		['/', '/index'],
		'Eight Sleep | The Intelligent Sleep System',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		'https://eightsleep.imgix.net/assets/homepage_meta.jpg',
		'https://www.eightsleep.com'
	),
	...generateMetaData(
		['/product/pod-cover', '/product/thermo-cover'],
		'Eight Sleep | Shop Pod',
		'Cool down your sleep with the Eight Sleep Pod. Clinically-proven to deliver up to one hour more of quality sleep every night. Try it risk-free.',
		'https://eightsleep.imgix.net/assets/shop_cover_meta.jpg',
		'https://www.eightsleep.com/product/pod-cover'
	),
	...generateMetaData(
		['/accessories'],
		'Eight Sleep | Shop Sleep Accessories',
		'Sleep accessories that make a difference.',
		'https://eightsleep.imgix.net/assets/accessories_meta.jpg',
		'https://www.eightsleep.com/accessories/'
	),
	...generateMetaData(
		['/community'],
		'Eight Sleep | Military and Student Discount',
		'The first bed engineered to improve your sleep through dynamic cooling and heating, detailed sleep tracking, and more. Starting at $2,595',
		podImage,
		'https://www.eightsleep.com/community/'
	),
	...generateMetaData(
		['/eight-pod-sleep-cool'],
		'Eight Sleep | Smart Cooling Mattress and Digital Sleep Coach',
		'The #1 smart temperature controlled mattress, designed to help you fall asleep faster and stay asleep. Smart cooling & warming sleep system, sleep and health tracking. Try it at home for 30 nights.',
		'https://eightsleep.imgix.net/meta_eight-pod-sleep-cool.png?v=1652251195',
		'https://www.eightsleep.com/eight-pod-sleep-cool/'
	),
	...generateMetaData(
		['/cover'],
		'Eight Sleep | Sleep Cool With Eight Sleep Mattress Cover',
		'No more blanket hogging or thermostat squabbles: each side of Eight Sleep Pod Cover can cool or heat to 55°-110°F.',
		'https://eightsleep.imgix.net/meta_pod-cover.png?v=1691590634',
		'https://www.eightsleep.com/cover/'
	),
	...generateMetaData(
		['/pod-cover'],
		'Eight Sleep | Bed Cooling System',
		'Intelligent cooling, heating, and elevation technology for any bed. Clinically-proven to deliver up to one hour more of quality sleep every night. Try it risk-free.',
		'https://eightsleep.imgix.net/assets/pod_cover_meta.png',
		'https://www.eightsleep.com/pod-cover/'
	),
	...generateMetaData(
		['/showfields'],
		'Eight Sleep | See The Pod at Showfields',
		'Try the Pod in person in New York City or San Francsico. See what the hype is all about.',
		podImage,
		'https://www.eightsleep.com/showfields'
	),
	...generateMetaData(
		['/stores'],
		'Eight Sleep | Try Our Award-Winning Technology Today',
		"Try the Pod at home for up to 30 nights. If you don't love it, return it for a full refund. Terms and conditions apply.",
		'https://eightsleep.imgix.net/meta_stores.png?v=1652251196',
		'https://www.eightsleep.com/stores'
	),
	...generateMetaData(
		['/product/foundation', '/product/foundation/'],
		'Eight Sleep | Mattress Foundation for The Pod',
		'A seamless slatted base that fits perfectly with your Pod.',
		'https://eightsleep.imgix.net/foundation_features_bg_desktop.jpg?309969',
		'https://www.eightsleep.com/product/foundation/'
	),
	...generateMetaData(
		['/product/the-carbon-air-pillow', '/product/the-carbon-air-pillow/'],
		'Eight Sleep | The Carbon Air Pillow',
		'Premium carbon-infused foam meets a proprietary phase-change material to absorb and dissipate heat away from the body',
		'https://eightsleep.imgix.net/meta_product_carbon-air-pillow.png?v=1652251195',
		'https://www.eightsleep.com/product/the-carbon-air-pillow/'
	),
	...generateMetaData(
		['/product/gravity-blanket', '/product/gravity-blanket/'],
		'Eight Sleep | Eight Gravity Blanket',
		'Relax before bedtime with our limited edition Gravity weighted blanket designed exclusively for Eight Sleep',
		'https://eightsleep.imgix.net/meta_product_gravity-blanket.png?v=1652251195',
		'https://www.eightsleep.com/product/gravity-blanket/'
	),
	...generateMetaData(
		['/product/waterproof-protector', '/product/waterproof-protector/'],
		'Eight Sleep | The Waterproof Protector',
		'Make your Pod last with our Waterproof Protector. Shield from spills and other accidents to keep your bed lasting for longer.',
		'https://eightsleep.imgix.net/meta_product_waterproof-protector.png?v=1652251195',
		'https://www.eightsleep.com/product/waterproof-protector/'
	),
	...generateMetaData(
		['/financing'],
		'Eight Sleep | Financing Options for the Pod',
		'Sleep now, pay later with easy and secure Affirm financing. As low as $0 down and 0% APR, for 36 months*. Pre-qualify today, no risk, no credit impact, immediate response.',
		'https://eightsleep.imgix.net/meta_financing.png?v=1652251196',
		'https://www.eightsleep.com/financing/'
	),
	...generateMetaData(
		['/404', '/404/'],
		'Eight Sleep | Page not found',
		'The #1 Smart Mattress, designed to help you fall asleep faster and stay asleep. After only 30 days, people sleeping on the Pod improve their rest significantly and wake up more refreshed. Try it at home for 30 nights, risk-free.',
		podImage,
		'https://www.eightsleep.com/404'
	),
	...generateMetaData(
		['/app-terms-conditions'],
		'Eight Sleep | App Terms and Conditions',
		'Join the Eight Sleep App, the only membership for sleep fitness. Track your sleep, see trends and personal insights, receive sleep coaching, access the content you need to wind down and wake up.',
		podImage,
		'https://www.eightsleep.com'
	),
	...generateMetaData(
		['/cart'],
		'Eight Sleep | Cart',
		'The #1 Smart Mattress, designed to help you fall asleep faster and stay asleep. After only 30 days, people sleeping on the Pod improve their rest significantly and wake up more refreshed. Try it at home for 30 nights, risk-free.',
		podImage,
		'https://www.eightsleep.com/cart'
	),
	...generateMetaData(
		['/email-not-verified'],
		'Eight Sleep | Email Verification - Failed',
		'Unlock the power of sleep fitness with Eight Sleep. Because the better you sleep, the better you everything.',
		podImage,
		'https://www.eightsleep.com/email-not-verified'
	),
	...generateMetaData(
		['/email-verified'],
		'Eight Sleep | Email Verification - Success',
		'Unlock the power of sleep fitness with Eight Sleep. Because the better you sleep, the better you everything.',
		podImage,
		'https://www.eightsleep.com/email-not-verified'
	),
	...generateMetaData(
		['/press'],
		'Eight Sleep | Press and Media Kit',
		"The world's first sleep fitness company. Award-winning technology with clinically-backed results.",
		'https://eightsleep.imgix.net/meta_press.png?v=1652251195',
		'https://www.eightsleep.com/press'
	),
	...generateMetaData(
		['/privacy-policy'],
		'Eight Sleep | Privacy Policy',
		'Unlock the power of sleep fitness with Eight Sleep. Because the better you sleep, the better you everything.',
		podImage,
		'https://www.eightsleep.com/privacy-policy'
	),
	...generateMetaData(
		['/referral'],
		'Eight Sleep | Refer A Friend',
		"Friends don't let friends sleep on a dumb bed. Refer your friends to Eight Sleep, send them $100 off and get $100 when they purchase.",
		podImage,
		'https://www.eightsleep.com/referral'
	),
	...generateMetaData(
		['/return-policy'],
		'Eight Sleep | Return Policy | 30 Nights Free Returns',
		'Try the Pod for up to 30 nights and see how it unlocks deeper more restful sleep',
		'https://eightsleep.imgix.net/meta_return-policy.png?v=1652251195',
		'https://www.eightsleep.com/return-policy'
	),
	...generateMetaData(
		['/reviews'],
		'Eight Sleep | Reviews of the Pod',
		'Believe the hype with over 50,000 happy sleepers. The only sleep products trusted by pro-athletes, Olympians, models, fitness trainers, and CEOs.',
		'https://eightsleep.imgix.net/meta_reviews.png?v=1652251196',
		'https://www.eightsleep.com/reviews'
	),
	...generateMetaData(
		['/warranty'],
		'Eight Sleep | Warranty',
		'Warranty details for Eight Sleep members. Everything you need, anytime, to keep your Pod in perfect shape. Forever.',
		'https://eightsleep.imgix.net/meta_warranty.png?v=1652251195',
		'https://www.eightsleep.com/warranty'
	),
	...generateMetaData(
		['/app'],
		'Eight Sleep | Digital Sleep Coach in Eight Sleep App',
		'All the tools you need to meet your sleep goals, including data, coaching, and tools like meditation and breathing routines.',
		podImage,
		'https://www.eightsleep.com/app'
	),
	...generateMetaData(
		['/technology'],
		'Eight Sleep | Technology Designed to Help You Sleep Better',
		'Patented and award-winning technology, relentlessly tested and improved to offer the most innovative solutions for sleep improvement. TiME Best Inventions Of The Year',
		'https://eightsleep.imgix.net/meta_technology.png?v=1652251196',
		'https://www.eightsleep.com/technology'
	),
	...generateMetaData(
		['/product/the-sleep-essentials-bundle', '/product/the-sleep-essentials-bundle/'],
		'Eight Sleep | Shop Sleep Essentials Bundle',
		'Everything you need to pair with your Pod: one pod protector and two Carbon Air Pillows.',
		'https://eightsleep.imgix.net/meta_product_sleep-essentials-bundle.png?v=1652251195',
		'https://www.eightsleep.com'
	),
	...generateMetaData(
		['/product/the-pod-sheet-set', '/product/the-pod-sheet-set/'],
		'Eight Sleep | Pod Sheet Set',
		'Designed for a Pod. 300-thread count, 100% bamboo.',
		'https://eightsleep.imgix.net/meta_product_pod-sheet-set.png?v=1652251196',
		'https://www.eightsleep.com/product/the-pod-sheet-set'
	),
	...generateMetaData(
		['/product/foundation', '/product/foundation/'],
		'Eight Sleep | Pod Foundation',
		'Designed for a Pod. Slatted, 100% solid spruce wood.',
		'https://eightsleep.imgix.net/meta_product_foundation.png?v=1652251195',
		'https://www.eightsleep.com/product/foundation'
	),
	...generateMetaData(
		['/product/melatonin-pro-bundle', '/product/melatonin-pro-bundle/'],
		'Eight Sleep | Melatonin Pro Bundle',
		'30 day supply of Magnesium CitraMate, Melaton-3, and RecoveryPro.',
		'https://eightsleep.imgix.net/meta_product_melatonin-pro-bundle.png?v=1652251195',
		'https://www.eightsleep.com/product/melatonin-pro-bundle'
	),
	...generateMetaData(
		['/product/air-lite-comforter', '/product/air-lite-comforter/'],
		'Eight Sleep | Air Lite Comforter',
		'The Air Lite Comforter will keep you cool and comfortable all night long.',
		'https://eightsleep.imgix.net/meta_product_air-lite-comforter.png?v=1652251195',
		'https://www.eightsleep.com/product/air-lite-comforter'
	),
	...generateMetaData(
		['/product/hypervolt-plus', '/product/hypervolt-plus/'],
		'Eight Sleep | Hypervolt+',
		"Hyperice's Hypervolt Plus uses percussion therapy to relieve muscle soreness and stiffness to improve range of motion and promote circulation.",
		'https://eightsleep.imgix.net/meta_product_hypervolt-plus.png?v=1652251196',
		'https://www.eightsleep.com/product/hypervolt-plus'
	),
	...generateMetaData(
		['/product/lavender-sleep-spray', '/product/lavender-sleep-spray/'],
		'Eight Sleep | Lavender Sleep Spray',
		'Enhance your sleep environment with this Lavender Aromatherapy spray designed to promote relaxation.',
		'https://eightsleep.imgix.net/meta_product_lavender-sleep-spray.png?v=1652251195',
		'https://www.eightsleep.com/product/lavender-sleep-spray'
	),
	...generateMetaData(
		['/product/blue-light-sleep-glasses', '/product/blue-light-sleep-glasses/'],
		'Eight Sleep | Blue Light Sleep Glasses',
		'Eight Sleep blue light glasses filters blue light to increase melatonin secretion and help you get quality sleep.',
		'https://eightsleep.imgix.net/felix_cropangle.jpg?v=1589909072',
		'https://www.eightsleep.com/product/blue-light-sleep-glasses'
	),
	...generateMetaData(
		['/product/venom-back', '/product/venom-back/'],
		'Eight Sleep | Venom Back',
		'A digital wearable device that combines heat and vibration to warm up and loosen sore muscles',
		'https://eightsleep.imgix.net/meta_product_venom-back.png?v=1652251195',
		'https://www.eightsleep.com/product/venom-back'
	),
	...generateMetaData(
		['/product/leg-pulse', '/product/leg-pulse/'],
		'Eight Sleep | Leg Pulse 2.0',
		'Compression Recovery Boots to reduce pain and soreness in the legs and help you recover quickly.',
		'https://eightsleep.imgix.net/meta_product_leg-pulse.png?v=1652251195',
		'https://www.eightsleep.com/product/leg-pulse'
	),
	...generateMetaData(
		['/product/prince-bedframe', '/product/prince-bedframe/'],
		'Eight Sleep | Prince Bedframe',
		'Enhance your sleep experience with the modern Prince Foundation from Modloft',
		'https://eightsleep.imgix.net/meta_product_prince-bedframe.png?v=1652251195',
		'https://www.eightsleep.com/product/prince-bedframe'
	),
	...generateMetaData(
		['/opengraph'],
		'Eight Sleep | Opengraph',
		'we are testing that opengraph life',
		'https://homepages.cae.wisc.edu/~ece533/images/peppers.png',
		'https://www.eightsleep.com/opengraph'
	),
	...generateMetaData(
		['/wall-of-love'],
		'Eight Sleep | Wall of Love',
		'Real reviews and experiences from Eight Sleep members',
		'https://eightsleep.imgix.net/assets/wall_of_love_meta.png',
		'https://www.eightsleep.com/wall-of-love'
	),
	...generateMetaData(
		['/sleep-improvement'],
		'Eight Sleep | Sleep Improvement',
		'Improve your sleep',
		'https://eightsleep.imgix.net/sleep-improvement.JPG?v=1614960470',
		'https://www.eightsleep.com/sleep-improvement'
	),
	...generateMetaData(
		['/justin'],
		'Eight Sleep | Sleep Fitness Technology: Smart Bed Cooling & Heating System For Better Sleep',
		'The #1 Smart Mattress, designed to help you fall asleep faster and stay asleep. After only 30 days, people sleeping on the Pod improve their rest significantly and wake up more refreshed. Try it at home for 30 nights, risk-free.',
		'https://eightsleep.imgix.net/justin_medeiros_meta.jpg?v=1634828866',
		'https://www.eightsleep.com/justin'
	),
	...generateMetaData(
		['/crypto-rebate'],
		'Eight Sleep | Sleep Fitness Technology: Smart Bed Cooling & Heating System For Better Sleep',
		'The #1 Smart Mattress, designed to help you fall asleep faster and stay asleep. After only 30 days, people sleeping on the Pod improve their rest significantly and wake up more refreshed. Try it at home for 30 nights, risk-free.',
		'https://eightsleep.imgix.net/image_17.jpg?v=1636077763',
		'https://www.eightsleep.com/crypto-rebate'
	),
	...generateMetaData(
		['/accessibility'],
		'Eight Sleep | Accessibility',
		'Eight Sleep has a commitment to ensuring that an equal experience and the website is accessible to all people, including those with disabilities.',
		'https://eightsleep.imgix.net/meta_accessibility.png?v=1652251196',
		'https://www.eightsleep.com/accessibility'
	),
	...generateMetaData(
		['/eu'],
		'Eight Sleep | Now in Europe',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/eu'
	),
	...generateMetaData(
		['/uk'],
		'Eight Sleep | Now in the UK',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/uk'
	),
	...generateMetaData(
		['/ca'],
		'Eight Sleep | Now in Canada',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/canada'
	),
	...generateMetaData(
		['/au'],
		'Eight Sleep | Now in Australia',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/canada'
	),
	...generateMetaData(
		['/dk'],
		'Eight Sleep | Now in Denmark',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/canada'
	),
	...generateMetaData(
		['/se'],
		'Eight Sleep | Now in Sweden',
		'Get higher quality sleep with personalized cooling, heating, and elevation technology for any bed. Sleep cooler, more comfortable, all year long. Clinically-proven.',
		lifestyleImage,
		'https://www.eightsleep.com/canada'
	),
	...generateMetaData(
		['/endel'],
		'Eight Sleep | The Future of Sound Meets the Future of Sleep',
		'Try the new Winter Sleep soundscape in your Eight Sleep app. Designed by Endel, exclusively for Eight Sleep members.',
		'https://eightsleep.imgix.net/endel-eightsleep-meta.png?v=1639757947',
		'https://www.eightsleep.com/endel'
	),
	...generateMetaData(
		['/redgerard'],
		'Eight Sleep | Sleep like a Gold Medalist',
		'Top ranked snowboarder and U.S. Olympic Gold-Medalist, Red Gerard, takes his sleep to a whole new level with the Pod.',
		'https://eightsleep.imgix.net/email-cap-red.png?v=1642552659',
		'https://www.eightsleep.com/redgerard'
	),
	...generateMetaData(
		['/travel'],
		'Eight Sleep | Experience the Future of Sleep',
		'Getting good sleep away from home just got a whole lot easier.',
		'https://eightsleep.imgix.net/hero_1_mobile.jpg?v=1643834591',
		'https://www.eightsleep.com/travel'
	),
	...generateMetaData(
		['/hosts'],
		'Eight Sleep | Bring a 5-star sleep experience to your Airbnb or VRBO',
		'Join our exclusive Hosts program for the Pod by Eight Sleep',
		'https://eightsleep.imgix.net/hosts_hero_desktop.jpg?v=1645615996',
		'https://www.eightsleep.com/hosts'
	),
	...generateMetaData(
		['/brooke'],
		'Eight Sleep | Brooke Wells',
		'7x CrossFit Games Athlete, Brooke Wells, takes her sleep to a whole new level with the Pod.',
		'https://eightsleep.imgix.net/brooke_meta.jpg?v=1646947461',
		'https://www.eightsleep.com/brooke'
	),
	...generateMetaData(
		['/travelerssleepguide'],
		"Eight Sleep | Traveler's Sleep Guide",
		'Because you should know where to find the best sleep, no matter how far you travel. Review a sleep on the road for an opportunity to stay at an Eight Sleep powered hotel.',
		'https://eightsleep.imgix.net/travelers_seo.png?v=1649220298',
		'https://www.eightsleep.com/travelerssleepguide'
	),
	...generateMetaData(
		['/strava'],
		'Eight Sleep x Strava',
		'Explore how cutting-edge sleep technology is revolutionizing athlete rest and performance.',
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-ecap.jpg',
		'https://www.eightsleep.com/strava'
	),
	...generateMetaData(
		['/efprocycling'],
		'Eight Sleep | EF Pro Cycling',
		'EF Pro Cycling are enhancing their sleep and recovery with Pod to find their competitive edge.',
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/efprocycling-ecap.jpg',
		'https://www.eightsleep.com/efprocycling'
	),
	...generateMetaData(
		['/hubermanvip'],
		"Eight Sleep | Win a chance to be a VIP at the Huberman Lab's event",
		'In partnership with Huberman Lab, Eight Sleep is giving you - and a plus one - a chance to win two VIP tickets to the live event series.',
		'https://eightsleep.imgix.net/huberman_meta.jpg?v=1664225855',
		'https://www.eightsleep.com/hubermanvip'
	),
	...generateMetaData(
		['/summeronice', '/summeronice/'],
		'Eight Sleep | Summer on Ice',
		'This summer we transport you to the Amalfi Coast with fashion photographer Elena Iv-skaya to share our favorite summer refreshments and celebrate the joy of cool sleep during our hottest days.',
		'https://eightsleep.imgix.net/mattress_wide.jpg?v=1686675279',
		'https://www.eightsleep.com/summeronice'
	),
	...generateMetaData(
		['/athletes', '/athletes/'],
		'Eight Sleep | Athletes Powered by the Pod',
		'Meet the athletes using the Pod to optimize their sleep and recovery, including George Russell, Patrick Peterson, Francisco Lindor and many more.',
		'https://eightsleep.imgix.net/featured_russell.jpg?v=1690443702',
		'https://www.eightsleep.com/athletes'
	),
	...generateMetaData(
		['/blog', '/blog/'],
		'Eight Sleep | Blog',
		'Discover more about sleep science, wellness, fitness and more at the Eight Sleep Blog',
		'https://eightsleep.imgix.net/assets/blog_meta.jpg',
		'https://www.eightsleep.com/blog'
	),
	...generateMetaData(
		['/blog/science', '/blog/science/'],
		'Eight Sleep | Sleep Science Blog',
		'Discover more about the science behind better sleep at the Eight Sleep Blog',
		'https://eightsleep.imgix.net/assets/blog_science_meta.jpg',
		'https://www.eightsleep.com/blog/science'
	),
	...generateMetaData(
		['/blog/wellness', '/blog/wellness/'],
		'Eight Sleep | Sleep Wellness Blog',
		'Discover more about sleep and wellness at the Eight Sleep Blog',
		'https://eightsleep.imgix.net/assets/blog_wellness_meta.jpg',
		'https://www.eightsleep.com/blog/wellness'
	),
	...generateMetaData(
		['/blog/fitness', '/blog/fitness/'],
		'Eight Sleep | Sleep Fitness Blog',
		'Discover more about sleep at the Eight Sleep Blog',
		'https://eightsleep.imgix.net/assets/blog_fitness_meta.jpg',
		'https://www.eightsleep.com/blog/fitness'
	),
	...generateMetaData(
		['/blog/sleep', '/blog/sleep/'],
		'Eight Sleep | Sleep Blog',
		'Discover more about sleep at the Eight Sleep Blog',
		'https://eightsleep.imgix.net/assets/blog_sleep_meta.jpg',
		'https://www.eightsleep.com/blog'
	),
	...generateMetaData(
		['/blog/8-reasons-to-buy-the-eight-sleep-pod-pro-today', '/blog/8-reasons-to-buy-the-eight-sleep-pod-pro-today/'],
		'Eight Sleep | 8 Reasons to Buy a Pod',
		'Discover the 8 reasons to buy an Eight Sleep: cooling and heating, dual zone, vibration alarm, sleep tracking, clinically-proven sleep improvement',
		'https://eightsleep.imgix.net/blog_meta.png?v=1690812415',
		'https://www.eightsleep.com/blog'
	),
	...generateMetaData(
		['/membership', '/membership/'],
		'Eight Sleep | Autopilot: AI for Better Sleep',
		'The intelligence behind the Pod. Your personal algorithm that caters to your needs for deeper, more restorative sleep. Clinically-proven.',
		'https://eightsleep.imgix.net/assets/autopilot_meta.jpg',
		'https://www.eightsleep.com/membership'
	),
	...generateMetaData(
		['/autopilot', '/autopilot/'],
		'Eight Sleep | Autopilot: AI for Better Sleep',
		'The intelligence behind the Pod. Your personal algorithm that caters to your needs for deeper, more restorative sleep. Clinically-proven.',
		'https://eightsleep.imgix.net/assets/autopilot_meta.jpg',
		'https://www.eightsleep.com/membership'
	),
	...generateMetaData(
		['/apl', '/apl/'],
		'Eight Sleep x APL | Move with the best. Sleep with the Best.',
		'Enter to win the ultimate movement & recovery package from us and our friends at @APL. Pod included.',
		'https://eightsleep.imgix.net/apl-wall-hires_1.jpg?v=1693251971',
		'https://www.eightsleep.com/apl'
	),
	...generateMetaData(
		['/hyperice', '/hyperice/'],
		'Eight Sleep | Peter Peterson Giveaway',
		'Enter for a chance to win an Eight Sleep Pod 3 and Normatec 3 Legs.',
		'https://eightsleep.imgix.net/hyperice_hero_mobile.jpg?v=1694715421',
		'https://www.eightsleep.com/hyperice'
	),
	...generateMetaData(
		['/higherdose', '/higherdose/'],
		'Eight Sleep x Higherdose',
		'Enter for the chance to win the Eight Sleep Pod and HigherDOSE bundle including the Sauna Blanket + PEMF Pro Mat + Red Light Face Mask',
		'https://eightsleep.imgix.net/higherdose/higherdose_hero_mobile.jpg?auto=format,compress&h=1000&dpr=1',
		'https://www.eightsleep.com/higherdose'
	),
	...Object.entries(vanityConfig)
		.map(([vanity, { saleName, heroSettings, partnerName, emailCapSettings }]) =>
			generateMetaData(
				[`/${vanity}`],
				`Eight Sleep | ${partnerName ?? saleName}`,
				heroSettings?.title ?? `${partnerName} approved`,
				emailCapSettings?.imageSrc ?? 'https://eightsleep.imgix.net/meta_pod-compare.png?v=1652251195',
				`https://www.eightsleep.com/${vanity}`
			)
		)
		.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
	...generateMetaData(
		['/rogan', '/rogan/'],
		'Eight Sleep | Joe Rogan',
		'Joe Rogan swears by the Pod to sleep at the perfect temperature every night',
		'https://eightsleep.imgix.net/meta_rogan_18c1eacd-1159-4c0e-b9c1-0ff59820f64e.png?v=1691590634',
		'https://www.eightsleep.com/rogan'
	),
	...generateMetaData(
		['/huberman', '/huberman/'],
		'Eight Sleep | Andrew Huberman',
		'Sleep at your perfect temperature each night with the Pod, Andrew Huberman’s go-to sleep solution.',
		'https://eightsleep.imgix.net/meta_huberman_9aa3ab44-4005-4489-b878-937fb88266f2.jpg?v=1691590633',
		'https://www.eightsleep.com/huberman'
	),
	...generateMetaData(
		['/aceyoursleep', '/aceyoursleep/'],
		'Eight Sleep | Ace Your Sleep',
		'This summer, Matteo Franceschetti invites you to experience his personal journey that merges a lifelong passion for tennis with a visionary approach to sleep fitness. Featuring Taylor Fritz, the #1 American male tennis player, this campaign highlights the critical role of sleep fitness both on and off the court. ',
		'https://eightsleep.imgix.net/aceyoursleep_meta.jpg?auto=format,compress',
		'https://www.eightsleep.com/aceyoursleep'
	),
	...generateMetaData(
		['/science', '/science/'],
		'Eight Sleep | Clinically-backed sleep fitness',
		'Get clinically-proven better sleep with the Pod.',
		'https://eightsleep.imgix.net/clinical_hero_bg.jpeg?fit=crop&w=1080&h=610&auto=format,compress',
		'https://www.eightsleep.com/science'
	),
}
